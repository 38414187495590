import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { API_ROUTE, IMAGE_API_ROUTE } from "../../api/constant";

const Ads = () => {
  const [ads, setAds] = useState([]);
  useEffect(() => {
    const getAds = async () => {
      const result = await fetch(API_ROUTE + "/api/customad/getCustomads");
      const data = await result.json();
      setAds(data.data);
    }
    getAds();
  }, [])
  return (
    <>
      {ads?.length > 0 ? <div className="my-10">
        <div className="px-5 flex justify-center items-center">
          <Marquee autoFill={true} pauseOnHover={true}>
            {ads?.length > 0 ? ads.map((item) => (
              <div key={item.id}>
                <a href={item.link}>
                  <img width="100px" src={`${IMAGE_API_ROUTE}/${item.image}`} alt="" />
                </a>
              </div>
            )) : null}
          </Marquee>
        </div>
      </div> : ""}

    </>
  );
};

export default Ads;
