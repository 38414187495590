// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  position: relative;
  width: 56px;
  height: 56px;
}

.spinner::before,
.spinner::after {
  content: '';
  width: 100%;
  height: 100%;
  animation: spinner-rfi6tk 1s infinite linear;
  box-sizing: border-box;
  border: 11.2px solid rgba(71,75,255,0.1);
  border-radius: 50%;
  position: absolute;
}

.spinner::before {
  animation-direction: reverse;
  border-right-color: #474bff;
  right: calc(50% - 5.6px);
}

.spinner::after {
  border-left-color: #474bff;
  left: calc(50% - 5.6px);
}

@keyframes spinner-rfi6tk {
  0% {
     transform: rotate(0deg);
  }

  50%, 100% {
     transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;;EAEE,WAAW;EACX,WAAW;EACX,YAAY;EACZ,4CAA4C;EAC5C,sBAAsB;EACtB,wCAAwC;EACxC,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,2BAA2B;EAC3B,wBAAwB;AAC1B;;AAEA;EACE,0BAA0B;EAC1B,uBAAuB;AACzB;;AAEA;EACE;KACG,uBAAuB;EAC1B;;EAEA;KACG,yBAAyB;EAC5B;AACF","sourcesContent":[".spinner {\n  position: relative;\n  width: 56px;\n  height: 56px;\n}\n\n.spinner::before,\n.spinner::after {\n  content: '';\n  width: 100%;\n  height: 100%;\n  animation: spinner-rfi6tk 1s infinite linear;\n  box-sizing: border-box;\n  border: 11.2px solid rgba(71,75,255,0.1);\n  border-radius: 50%;\n  position: absolute;\n}\n\n.spinner::before {\n  animation-direction: reverse;\n  border-right-color: #474bff;\n  right: calc(50% - 5.6px);\n}\n\n.spinner::after {\n  border-left-color: #474bff;\n  left: calc(50% - 5.6px);\n}\n\n@keyframes spinner-rfi6tk {\n  0% {\n     transform: rotate(0deg);\n  }\n\n  50%, 100% {\n     transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
