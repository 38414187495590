import React, { useState } from 'react'
import Community from './Community'
import PostList from './PostList'

const AllPost = () => {
  const [searchTerm , setSearchTerm] = useState('');


  return (
    <div>
      <Community setSearchTerm={setSearchTerm}/>
      <PostList searchTerm={searchTerm}/>
    </div>
  )
}

export default AllPost;
