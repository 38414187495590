import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { poster } from './../api/poster'
import LoadingSVG from '../components/LoadingSvg'
import { IMAGE_API_ROUTE } from '../api/constant'
function CareSheet() {
  const {
    data: categoryData,
    refetch: categoryRefetch,
    isLoading: categoryLoading,
  } = useQuery(
    ['getCategories'],
    async () =>
      await poster({
        url: `/api/user/getCategories`,
        method: 'GET',
        // authToken: currentUser?.token,
      }),
    {
      retry: 3,
    },
  )

  // let category = [
  //   {
  //     name: 'Health',
  //     icon: process.env.PUBLIC_URL + '/images/categories/health.svg',
  //     id: 'healthcare',
  //     categoryId: '11',
  //   },
  //   {
  //     name: 'Equipment',
  //     icon: process.env.PUBLIC_URL + '/images/categories/equ.svg',
  //     id: 'equipment',
  //     categoryId: '12',
  //   },
  //   {
  //     name: 'Environment',
  //     icon: process.env.PUBLIC_URL + '/images/categories/env.svg',
  //     id: 'environment',
  //     categoryId: '13',
  //   },
  //   {
  //     name: 'Social needs',
  //     icon: process.env.PUBLIC_URL + '/images/categories/social.svg',
  //     vicon: '',
  //     id: 'social needs',
  //     categoryId: '14',
  //   },
  //   {
  //     name: 'Miscellaneous',
  //     icon: process.env.PUBLIC_URL + '/images/categories/miscellaneous.svg',
  //     vicon: '',
  //     id: 'miscellaneous',
  //     categoryId: '16',
  //   },
  // ]
  return (
    <div>
      <div className="p-6">
        <h3 className="py-3 font-bold text-left">Care sheet categories</h3>
        {!categoryLoading ? (
          <div className="">
            {categoryData?.data?.map((category, key) => (
              <Link to={'/sheets/' + category?.id} key={key}>
                <div className="py-2">
                  <div className="flex rounded-md bg-white drop-shadow-xl">
                    <div className="w-[100px]">
                      <img
                        className="w-full rounded-tl-md rounded-bl-md"
                        src={IMAGE_API_ROUTE + category.image}
                        alt={key}
                      />
                    </div>
                    <div className="flex justify-between items-center w-full px-4 text-[#353535] font-medium ">
                      <span>{category.category}</span>
                      <span>
                        <MdKeyboardArrowRight size={24} />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <LoadingSVG />
        )}
      </div>
    </div>
  )
}

export default CareSheet
