import React, { useEffect, useState } from "react";
import { IMAGE_API_ROUTE, API_ROUTE } from "../api/constant";
import TeamModal from "../components/Modal/TeamModal";
import Loader from "../components/Loader/Loader";
const OurTeam = () => {
  const [team, setTeam] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function getTeam() {
      setIsLoading(true);
      var res = await fetch(API_ROUTE + "/api/team/getTeam");
      var data = await res.json();
      setTeam(data.data);

      //console.log(data);

      setIsLoading(false);
    }
    getTeam();
  }, []);

  return (
    <>
      {isLoading ? <Loader /> : null}
      <div className="px-5 mx-auto mt-5 max-w-6xl">
        <div className="text-center text-2xl font-semibold mt-6">Our Team</div>
        <div className="mt-5">
          <div className="flex flex-wrap gap-3 justify-center items-start">
            {team.length > 0
              ? team.map((item) => (
                  <div
                    className="w-full lg:w-[32%] border-2 flex flex-col justify-center items-center"
                    key={item.id}
                  >
                    
                      <img
                        src={IMAGE_API_ROUTE + "/" + item.image}
                        className="rounded shadow md:w-68 md:h-74 object-cover w-full h-full"
                        alt=""
                      />
                    
                    <div className="p-3 w-56">
                      <div className="font-bold text-2xl">{item.name}</div>
                      <div>{item.designation}</div>
                      <div>
                        <TeamModal item={item} />
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurTeam;
