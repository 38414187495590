import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { BsArrowLeftShort } from 'react-icons/bs'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { poster } from '../../api/poster'
import { IMAGE_API_ROUTE } from '../../api/constant'

function CareBookVideos() {
  const {
    data: categoryData,
    refetch: categoryRefetch,
    isLoading: categoryLoading,
  } = useQuery(
    ['getCategories'],
    async () =>
      await poster({
        url: `/api/user/getCategories`,
        method: 'GET',
        // authToken: currentUser?.token,
      }),
    {
      retry: 3,
    },
  )

  return (
    <div>
      <div className="p-6">
        <h3 className="py-3 font-bold text-left">Care Videos categories</h3>
        <div>
          {categoryData?.data?.map((category, key) => (
            <Link to={'/bookmark/video/' + category.id}>
              <div className="py-2" key={key}>
                <div className="flex rounded-md bg-white drop-shadow-xl">
                  <div className="w-[100px]">
                    <img
                      className="w-full rounded-tl-md rounded-bl-md"
                      src={IMAGE_API_ROUTE + category.image}
                      alt={key}
                    />
                  </div>
                  <div className="flex justify-between items-center w-full px-4 text-[#353535] font-medium ">
                    <span>{category.category}</span>
                    <span>
                      <MdKeyboardArrowRight size={24} />
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CareBookVideos
