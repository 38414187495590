import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { AiOutlineEdit, AiOutlineInfoCircle } from 'react-icons/ai'
import { useLocation, useNavigate } from 'react-router-dom'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import * as Yup from 'yup'
import useRestAPI from '../../api'
function CareGiver() {
  tippy('#giverinfo', {
    content: 'Caregiver is the person who will take care of the Care receiver',
  })
  const { getCareGiver, addCareGiver } = useRestAPI()
  const [FormVisable, setFormVisable] = useState(false)
  const [careProfile, setCareProfile] = useState({})
  const [editNumber, setEditNumber] = useState(null)
  const location = useLocation()
  const navigate = useNavigate()

  // Disable back button if user navigated from SignupScreen
  const disableBackButton = location.state && location.state.from === 'register'
  // Go back to the previous screen
  const goBack = () => {
    if (disableBackButton) {
      // Do nothing
      return
    }
    navigate(-1)
  }
  useEffect(() => {
    return () => {
      getData()
    }
  }, [])

  const getData = () => {
    getCareGiver({ setFormVisable, setCareProfile });
  }

  return (
    <div>
      <div className="pt-3  md:max-w-[1300px] md:mx-auto ">
        <div
          className="pt-2 px-3 font-bold cursor-pointer "
          onClick={() => goBack()}
        >
          &lt;-Back{' '}
        </div>
      </div>
      {FormVisable ? (
        <CareGiverForm
          careProfile={careProfile}
          editNumber={editNumber}
          getData={getData}
          addCareGiver={addCareGiver}
          setFormVisable={setFormVisable}
          setCareProfile={setCareProfile}
        />
      ) : (
        <div>
          <div className="px-6 md:max-w-[1300px] md:mx-auto">
            <div className="flex justify-between items-center">
              <h3 className="text-start py-5 flex  items-center  gap-2">
                <span>Caregiver’s profile</span>{' '}
                <span id="giverinfo">
                  <AiOutlineInfoCircle />
                </span>
              </h3>
            </div>
          </div>
          <div className='px-6 md:max-w-[1300px] md:mx-auto flex gap-20'>
            {Object.entries(careProfile).map(([key, value]) => (
              <div key={key} className="lg:max-w-1/3 md:max-w-1/2 shadow-md p-3">
                <div className=''>
                  <AiOutlineEdit
                    className="cursor-pointer"
                    onClick={() => {
                      setEditNumber(key);
                      setFormVisable(true);
                    }}
                  />
                </div>
                <div className="w-full text-start flex justify-start gap-10 leading-loose  my-5">
                  <div className="text-[#616161] ">
                    <div>Name</div>
                    <div>Age</div>
                    <div>Gender</div>
                    <div>Relationship</div>
                  </div>
                  <div className="border"> </div>

                  <div className="text-[#454545] font-semibold ">
                    <div>{value.cg_name}</div>
                    <div>{value.cg_age}</div>
                    <div>{value.cg_gender}</div>
                    <div>{value.cg_relationship}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* <Link to="/profile/receiver" className="flex justify-center ">
            <button className="bg-[#0566B1] font-semibold   text-white py-2 rounded-md min-w-[150px] my-10">
              Next
            </button>
          </Link> */}
        </div>
      )}
      <div className="flex justify-center gap-10">
        <div className="flex justify-center ">
          <button className="bg-[#0566B1] font-semibold text-white py-2 rounded-md min-w-[150px] my-10" onClick={() => {setEditNumber(null); setFormVisable(!FormVisable)}}> {FormVisable ? "View Caregivers" : "Add Caregiver"}</button>
        </div>
      </div>
    </div>
  )
}

function CareGiverForm({
  careProfile,
  editNumber,
  getData,
  addCareGiver,
  setFormVisable,
  setCareProfile,
}) {
  careProfile = editNumber ? careProfile[editNumber] : null;
  const formik = useFormik({
    initialValues: {
      cg_id: careProfile?.cg_id,
      name: careProfile?.cg_name,
      age: careProfile?.cg_age,
      gender: careProfile?.cg_gender,
      relationship: careProfile?.cg_relationship,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name  is required'),
      age: Yup.number()
        .min('18', 'Age should be greater than 18 ')
        .max('150', 'Age should be less than 150. ')
        .required('Age is required'),
      gender: Yup.string().required('Gender is required'),
      relationship: Yup.string().required('Relationship is required'),
    }),
    onSubmit: (values) => {
      console.log(values)
      addCareGiver({ setFormVisable, setCareProfile, values, getData })
    },
  })
  return (
    <div>
      <div>
        <div className="px-6 md:max-w-[1300px] md:mx-auto">
          <h3 className="text-start py-5 flex  items-center  gap-2">
            <span>Caregiver’s profile</span>
            <span
              id="giverinfo"
              onMouseOver={() => {
                tippy('#giverinfo', {
                  content:
                    'Caregiver is the person who will take care of the Care receiver',
                })
              }}
            >
              <AiOutlineInfoCircle />
            </span>
          </h3>
          <div className="w-full ">
            <form
              className="flex flex-col gap-4"
              onSubmit={formik.handleSubmit}
            >
              <div className="w-full">
                {' '}
                <input
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                  placeholder="Name"
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>{' '}
              <div className="w-full">
                {' '}
                <input
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                  placeholder="Age"
                  type="number"
                  name="age"
                  value={formik.values.age}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.age && formik.errors.age ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.age}
                  </div>
                ) : null}
              </div>
              <div className="w-full">
                {' '}
                <select
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                  placeholder=""
                  defaultValue={careProfile?.cg_gender}
                  name="gender"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select Gender </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                {formik.touched.gender && formik.errors.gender ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.gender}
                  </div>
                ) : null}
              </div>
              <div className="w-full">
                {' '}
                <select
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                  placeholder="Relationship"
                  name="relationship"
                  defaultValue={careProfile?.cg_relationship}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select Relationship </option>
                  <option value="family">Family</option>
                  <option value="friend">Friend</option>
                  <option value="professional">Professional</option>
                  <option value="other">Other</option>
                </select>
                {formik.touched.relationship && formik.errors.relationship ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.relationship}
                  </div>
                ) : null}
              </div>
              <button className="bg-[#0566B1] font-semibold   text-white py-2 rounded-md min-w-[150px] my-10">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CareGiver
