import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { BsPlayCircle } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { poster } from '../api/poster'
import { IMAGE_API_ROUTE } from '../api/constant'

function CareVideo() {
  const {
    data: categoryData,
    refetch: categoryRefetch,
    isLoading: categoryLoading,
  } = useQuery(
    ['getCategories'],
    async () =>
      await poster({
        url: `/api/user/getCategories`,
        method: 'GET',
        // authToken: currentUser?.token,
      }),
    {
      retry: 3,
    },
  )
  // let category = [
  //   {
  //     name: 'Health',
  //     icon: process.env.PUBLIC_URL + '/images/categories/health.svg',
  //     id: 'healthcare',
  //     categoryId: '11',
  //   },
  //   {
  //     name: 'Equipment',
  //     icon: process.env.PUBLIC_URL + '/images/categories/equ.svg',
  //     id: 'equipment',
  //     categoryId: '12',
  //   },
  //   {
  //     name: 'Environment',
  //     icon: process.env.PUBLIC_URL + '/images/categories/env.svg',
  //     id: 'environment',
  //     categoryId: '13',
  //   },
  //   {
  //     name: 'Social needs',
  //     icon: process.env.PUBLIC_URL + '/images/categories/social.svg',
  //     vicon: '',
  //     id: 'social needs',
  //     categoryId: '14',
  //   },
  //   {
  //     name: 'Miscellaneous',
  //     icon: process.env.PUBLIC_URL + '/images/categories/miscellaneous.svg',
  //     vicon: '',
  //     id: 'miscellaneous',
  //     categoryId: '16',
  //   },
  // ]
  return (
    <div>
      <div className="p-6">
        <h3 className="py-3 font-bold text-left">Care Video categories</h3>
        <div>
          {categoryData?.data?.map((category, key) => (
            <Link to={'/videos/' + category.id} key={key}>
              <div className="py-2 cursor-pointer ">
                <div className="flex rounded-md bg-white drop-shadow-xl">
                  <div className="w-[100px]">
                    <img
                      className="w-full rounded-tl-md rounded-bl-md"
                      src={IMAGE_API_ROUTE + category.image}
                      alt={key}
                    />
                  </div>
                  <div className="flex justify-between items-center w-full px-4 text-[#353535] font-semibold ">
                    <span>{category.category}</span>
                    <span>
                      <BsPlayCircle size={34} color={category?.hexcode} />
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

const YoutubeVideo = () => {
  return (
    <div>
      <div className="py-5  mx-6">
        {' '}
        {/* <div className="w-full"> */}
        <iframe
          width="100%"
          height="179em"
          src="https://www.youtube.com/embed/bVNPRCv431M"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        {/* </div> */}
        <div className="bg-[#B7DFFD] py-4 rounded-bl-md rounded-br-md text-sm  text-[#04518D] text">
          <span className="font-semibold">
            <b>Intro:</b>
          </span>{' '}
          Dr.Sanjay Suri & Dr Shubhada Suri
        </div>
      </div>
    </div>
  )
}
export default CareVideo
