import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { API_ROUTE } from "../api/constant";
import PostCard from "./PostCard";

// Debounce Hook
const useDebounce = (callback, delay) => {
  const timerRef = React.useRef();
  const debouncedCallback = useCallback(
    (...args) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );
  return debouncedCallback;
};

const PostList = ({ userId, postId, searchTerm }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [lastSearchTerm, setLastSearchTerm] = useState(searchTerm); // To detect search term change

  const fetchPosts = async (reset = false) => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const url = postId
        ? `${API_ROUTE}/api/post?postId=${postId}&page=${page}`
        : userId
        ? `${API_ROUTE}/api/post?userId=${userId}&page=${page}&search=${searchTerm}`
        : `${API_ROUTE}/api/post?page=${page}&search=${searchTerm}`;

      const response = await axios.get(url);

      setPosts((prevPosts) => {
        if (reset) {
          return response.data.data; // Reset when search term changes
        }
        // For pagination, append new posts
        const updatedPosts = response.data.data.reduce(
          (acc, newPost) => {
            const existingPostIndex = acc.findIndex(
              (existingPost) => existingPost.id === newPost.id
            );
            if (existingPostIndex >= 0) {
              acc[existingPostIndex] = newPost;
            } else {
              acc.push(newPost);
            }
            return acc;
          },
          [...prevPosts]
        );
        return updatedPosts;
      });

      setHasMore(response.data.data.length > 0);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle pagination on scroll
  const handleScroll = useDebounce(() => {
    if (loading || !hasMore) return;
    console.log("scroll initaited")
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100
    ) {
      setPage((prevPage) => prevPage + 1);
      console.log(page)
    }
  }, 200); // Debounce delay

  useEffect(() => {
    if (searchTerm !== lastSearchTerm) {
      // Reset page and posts when search term changes
      setPage(1);
      setPosts([]);
      setHasMore(true);
      setLastSearchTerm(searchTerm);
    } else {
      // Fetch posts with the current page and search term
      fetchPosts(page === 1); // Reset posts only when page is 1 (search changed)
    }
  }, [userId, postId, page, searchTerm, lastSearchTerm]);

  // Attach scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div className="post-list pt-0 p-4 lg:px-48 lg:pt-4 lg:pb-6">
      {posts.map((post, key) => (
        <PostCard
          key={key}
          postId={post.id}
          username={post.creator_name}
          title={post.title}
          description={post.description}
          image={post.image}
          created_at={post.created_at}
          like_count={post.total_likes}
          comment_count={post.total_comments}
          showUserDetails={!userId}
        />
      ))}
      {loading && <div className="loader text-center">Loading...</div>}
    </div>
  );
};

export default PostList;
