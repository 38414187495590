import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiCornerUpRight, FiSend } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API_ROUTE } from "../api/constant";
import PostCard from "./PostCard";
const UserIcon = ({ icon }) => {
  return (
    <div className="rounded-full h-12 w-12 bg-white flex items-center justify-center shadow-md">
      {icon}
    </div>
  );
};
const PostDetails = () => {
  const userId = JSON.parse(localStorage.getItem("token")).id;
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [likeCount, setLikeCount] = useState();
  const [liked, setLiked] = useState(false);
  const [commentCount, setCommentCount] = useState();
  const [comment, setComment] = useState("");
  const [report, setReport] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showReplyBoxIndex, setShowReplyBoxIndex] = useState(null);
  const [reply, setReply] = useState("");
  const [currentCommentId, setCurrentCommentId] = useState(null);
  useEffect(() => {
    setLikeCount();
    setCommentCount();
  }, []);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleIconClick = (commentID, index) => {
    setShowReplyBoxIndex(index);
    setCurrentCommentId(commentID);
  };
  const handleReplyChange = (e) => {
    setReply(e.target.value);
  };
  const handleReplySubmit = async () => {
    try {
      if (!currentCommentId) {
        console.error("Comment ID is not set");
        return;
      }
      const response = await axios.post(`${API_ROUTE}/api/post/reply`, {
        comment_id: currentCommentId,
        userId: userId,
        reply,
      });
      if (response.status === 201) {
        console.log("Reply submitted:", reply);
        setReply("");
        setShowReplyBoxIndex(null);
        fetchedPost();
      } else {
        console.error("Failed to post reply");
      }
    } catch (error) {
      console.error("Error posting reply:", error);
    }
  };
  const handleCommentSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${API_ROUTE}/api/post/postcomment`, {
        post_id: postId,
        userId: userId,
        replies: comment,
      });
      setCommentCount((prevCount) => prevCount + 1);
      toast.success("Comment Posted Successfully!");
      setComment("");
      fetchedPost();
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Comment not posted, something went wrong!")

    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${API_ROUTE}/api/post/postreport`, {
        post_id: postId,
        userId: userId,
        message: report,
      });
      setReport("");
      handleCloseModal();
      toast.success("Report Submitted Successfully");
    } catch (error) {
      toast.error("Error Submitting report");
      console.log("Error Submitting report", error);
    }
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  const handleReportChange = (event) => {
    setReport(event.target.value);
  };

  const fetchedPost = async () => {
    try {
      const response = await axios.get(
        `${API_ROUTE}/api/post?postId=${postId}`
      );
      let postData = response.data.data;
      setPost(postData);
      setLikeCount(postData.total_likes);
      setCommentCount(postData.total_comments);
    } catch (error) {
      console.log("Error fetching post:", error);
    }
  };
  useEffect(() => {
    fetchedPost();
  }, []);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };
  if (!post) {
    return <div>Loading...</div>;
  }
  return (
    <div className="bg-[#E2EAFF] p-6 md:p-14 lg:p-20 min-h-[84vh]">
      <div onClick={handleBackClick} className="flex items-center text-[#04518D] pt-4 md:pt-5 font-bold cursor-pointer">
        <IoIosArrowBack className="text-xl md:text-2xl" />
        <p className="ml-2 text-sm md:text-base">Back</p>
      </div>
      <div className="text-center">
        <h1 className="text-2xl md:text-4xl font-bold text-[#04518D] py-4 md:py-5">
          Post Details
        </h1>
      </div>
      <PostCard
        bgcolor={'bg-white'}
        postId={post.id}
        username={post.creator_name}
        title={post.title}
        description={post.description}
        image={post.image}
        created_at={post.created_at}
        like_count={post.total_likes}
        comment_count={post.total_comments}
        showUserDetails={true}
        noborder={true}
        viewBtn={false}
        hrWidth={"w-[96%] mx-auto"}
      />

      <h2 className="py-2 text-[#04518D] font-bold text-lg md:text-xl w-full">
        Comments
      </h2>
      <div className="mt-4">
        {post.comments.map((comment, index) => (
          <div key={index} className="bg-[white] p-5 mb-6 md:mb-8 shadow-md">
            <div className="flex gap-4 md:gap-8">
              <UserIcon className="w-1/12" icon={comment.response_user_name[0]} />
              <div className="flex-column w-11/12">
                <h1 className="text-base md:text-lg font-bold">{comment.response_user_name}</h1>
                <div className="flex gap-2 my-3">
                  <p className="w-11/12 text-sm md:text-base">{comment.response}</p>
                  <FiCornerUpRight
                    className="w-1/12 text-lg translate-x-2 md:translate-x-6 lg:translate-x-10 "
                    onClick={() => handleIconClick(comment.response_id, index)}
                  />
                </div>
                {comment.replies ? comment.replies
                  .map((reply, replyIndex) => (
                    <div className="p-2 my-6 bg-[#D7ECFC] rounded-lg flex gap-2 md:gap-4 shadow-md">
                      <UserIcon className="w-1/12" icon={reply.user_name[0]} />
                      <div className="flex-column w-11/12">
                        <h1 className="text-base md:text-lg font-bold">{reply.user_name}</h1>
                        <div className="flex gap-2 my-1">
                          <p className="w-11/12 text-sm md:text-base">{reply.reply}</p>
                          {/* <FiCornerUpRight
                            className="w-1/12 text-sm"
                            onClick={() => handleIconClick(comment.response_id, index)}
                          /> */}
                        </div>
                      </div>
                    </div>

                  )) : ""}
                {showReplyBoxIndex === index && (
                  <div className="w-full flex bg-white border-gray-200	border-2">
                    <input
                      type="text"
                      className="w-full p-3"
                      value={reply}
                      onChange={handleReplyChange}
                      placeholder="Write your reply..."
                    />
                    <button
                      className="bg-none text-gray-500 rounded-lg rounded-none px-3"
                      onClick={handleReplySubmit}
                    >
                      <FiSend size={20} />
                    </button>
                  </div>
                )}
              </div>
            </div>


          </div>
        ))}
      </div>
      <form className="w-full flex bg-white border-gray-400	border-2"
        onSubmit={handleCommentSubmit} >
        <textarea
          required
          className="w-full p-3"
          value={comment}
          onChange={handleCommentChange}
          placeholder="Write your comment..."
        />
        <button type="submit" className=" bg-none text-gray-500 rounded-lg rounded-none px-3 pb-3">
          <FiSend size={20} />
        </button>
      </form>
    </div>
  );
};
export default PostDetails;
