import React, { useContext, useEffect, useState } from 'react'
import {
  BsArrowLeftShort,
  BsBookmark,
  BsFillBookmarkFill,
} from 'react-icons/bs'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useRestAPI from '../api'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { poster } from '../api/poster'
import LoadingSVG from '../components/LoadingSvg'
import { MdOutlineArrowBack } from 'react-icons/md'
import { AuthContext } from '../context/AuthContextProvider'
import { IMAGE_API_ROUTE } from '../api/constant'

function CareVideoCategory() {
  const { category: id } = useParams()
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [Videos, setVideos] = useState([])
  const { currentUser } = useContext(AuthContext)
  const {
    data: categoryData,
    mutateAsync: categoryMutateAsync,
    isLoading: categoryLoading,
  } = useMutation(
    [`getVideos${id}`],
    async (data) =>
      await poster({
        url: `/api/user/getVideos`,
        method: 'POST',
        bodyData: data,
      }),
  )

  console.log(categoryData, currentUser)

  useEffect(() => {
    if (currentUser?.id) {
      categoryMutateAsync({
        categoryId: id,
        userId: currentUser?.id,
      })
    }
  }, [currentUser, id])

  let category = [
    {
      name: 'Health',
      icon: process.env.PUBLIC_URL + '/images/categories/health.svg',
      vicon: '#B19DE5',
      bg: '#F1ECFF',
      id: '11',
    },
    {
      name: 'Equipment',
      icon: process.env.PUBLIC_URL + '/images/categories/equ.svg',
      vicon: '#F88B6F',
      bg: '#FFF3EC',
      id: '12',
    },
    {
      name: 'Environment',
      icon: process.env.PUBLIC_URL + '/images/categories/env.svg',
      vicon: '#4FD490',
      bg: '#ECFFF0',
      id: '13',
    },
    {
      name: 'Social needs',
      icon: process.env.PUBLIC_URL + '/images/categories/social.svg',
      vicon: '#F9C26F',
      bg: '#FFFDEC',
      id: '14',
    },
    {
      name: 'Miscellaneous',
      icon: process.env.PUBLIC_URL + '/images/categories/miscellaneous.svg',
      vicon: '#8B8279',
      bg: '#FFF4EC',
      id: '16',
    },
  ]
  let title = category.find((item) => item.id === id)

  const YoutubeVideo = ({ category, setVideos, Videos, index }) => {
    const { deleteVideoBookmark, addVideoBookmark } = useRestAPI()
    console.log(category)
    return (
      <div>
        <div className="py-5  mx-6">
          {/* <div className="w-full"> */}
          <iframe
            width="100%"
            className="h-[17em] md:h-[500px] rounded-md"
            src={category.video}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          {/* </div> */}
          <div className=" bg-white  text-left py-2 rounded-bl-md rounded-br-md text-sm  text-[#04518D] text">
            <div className="flex items-center justify-between   gap-3">
              <div className="flex items-center  gap-3 px-5">
                {/* <img
                  className="rounded-full w-8 "
                  src={process.env.PUBLIC_URL + title.icon}
                  alt="i"
                /> */}
                <div>
                  <div className="text-[#353535]">{category.subcategory}</div>{' '}
                </div>
              </div>
              <div className="item-center flex justify-end ">
                {category?.is_bookmarked ? (
                  <BsFillBookmarkFill
                    size={24}
                    cursor="pointer"
                    onClick={() => {
                      setLoading(true)
                      deleteVideoBookmark(category.id)
                        .then(() => {
                          // setVideos((prevVideos) => {
                          //   const updatedVideos = [...prevVideos]
                          //   updatedVideos[index] = {
                          //     ...updatedVideos[index],
                          //     is_bookmarked: 0,
                          //   }
                          //   return updatedVideos
                          // })
                          categoryMutateAsync({
                            categoryId: id,
                            userId: currentUser?.id,
                          })
                          setLoading(false)
                        })
                        .catch(() => {
                          setLoading(false)
                        })
                    }}
                  />
                ) : (
                  <BsBookmark
                    size={24}
                    cursor="pointer"
                    onClick={() => {
                      setLoading(true)
                      addVideoBookmark(category.id)
                        .then(() => {
                          // setVideos((prevVideos) => {
                          //   const updatedVideos = [...prevVideos]
                          //   updatedVideos[index] = {
                          //     ...updatedVideos[index],
                          //     is_bookmarked: 1,
                          //   }
                          //   return updatedVideos
                          // })
                          categoryMutateAsync({
                            categoryId: id,
                            userId: currentUser?.id,
                          })
                          setLoading(false)
                        })
                        .catch(() => {
                          setLoading(false)
                        })
                    }}
                  />
                )}
                Bookmark
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className={`bg-[${title.bg}]`}>
        <div className="p-6">
          <div>
            <span
              className={`flex gap-5 items-center font-bold cursor-pointer  text-[${title.vicon}]`}
              onClick={() => navigate(-1)}
            >
              <MdOutlineArrowBack />
              <span>Back</span>
            </span>
          </div>
          <h3 className="py-3 font-bold text-left">
            Care Videos-<span className="">{title.name}</span>
          </h3>

          {categoryLoading ? (
            <LoadingSVG />
          ) : (
            <div className="md:max-w-[1300px] md:mx-auto">
              {categoryData?.articles?.map((category, key) => (
                <YoutubeVideo
                  category={category}
                  key={key}
                  index={key}
                  setVideos={setVideos}
                  Videos={Videos}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <Categories />
    </div>
  )
}

const Categories = () => {
  const { category: id } = useParams()
  const { currentUser } = useContext(AuthContext)
  const { mutateAsync: categoryMutateAsync } = useMutation(
    [`getVideos${id}`],
    async (data) =>
      await poster({
        url: `/api/user/getVideos`,
        method: 'POST',
        bodyData: data,
      }),
  )
  const {
    data: categoryData,
    refetch: categoryRefetch,
    isLoading: categoryLoading,
  } = useQuery(
    [`getCategories${id}`],
    async () =>
      await poster({
        url: `/api/user/getCategories`,
        method: 'GET',
        // authToken: currentUser?.token,
      }),
  )

  return (
    <>
      <div className="p-6">
        <h3 className="py-3 font-bold text-left">Other categories</h3>
        <div className="flex justify-between">
          <Swiper
            spaceBetween={50}
            slidesPerView={4}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            <>
              {categoryLoading ? (
                <LoadingSVG />
              ) : (
                categoryData?.data?.map((category, key) => (
                  <>
                    {id != category.id && (
                      <SwiperSlide key={key}>
                        <Link
                          to={'/videos/' + category.id}
                          onClick={() => {
                            categoryMutateAsync({
                              categoryId: id,
                              userId: currentUser?.id,
                            })
                          }}
                        >
                          <div
                            key={key}
                            className="flex justify-center items-center flex-col "
                          >
                            <div>
                              <img
                                className="rounded-full"
                                src={IMAGE_API_ROUTE + category.image}
                                alt={category.category}
                              />
                            </div>
                            <div className="mt-2">{category.category}</div>
                          </div>
                        </Link>
                      </SwiperSlide>
                    )}
                  </>
                ))
              )}
            </>
          </Swiper>
        </div>
      </div>
    </>
  )
}

let otherCategory = [
  {
    name: 'Health',
    icon: process.env.PUBLIC_URL + '/images/categories/health.svg',
    id: 'health',
    categoryId: 11,
  },
  {
    name: 'Equipment',
    icon: process.env.PUBLIC_URL + '/images/categories/equ.svg',
    id: 'equipment',
    categoryId: 12,
  },
  {
    name: 'Environment',
    icon: process.env.PUBLIC_URL + '/images/categories/env.svg',
    id: 'environment',
    categoryId: 13,
  },
  {
    name: 'Social needs',
    icon: process.env.PUBLIC_URL + '/images/categories/social.svg',
    vicon: '',
    id: 'social',
    categoryId: 14,
  },
  {
    name: 'Miscellaneous',
    icon: process.env.PUBLIC_URL + '/images/categories/miscellaneous.svg',
    vicon: '',
    id: 'miscellaneous',
    categoryId: 16,
  },
]

export default CareVideoCategory
