import { useState, useEffect, Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineEdit, AiFillPrinter } from 'react-icons/ai'
import useRestAPI from '../api'
import questions from './Question/questions.json'
import { AuthContext } from '../context/AuthContextProvider'
import axios from 'axios'
import { API_ROUTE } from '../api/constant'
import LoadingSVG from '../components/LoadingSvg'

function CarePlans() {
  const { getCarePlanQuestions, sendPrintMail } = useRestAPI()
  const { currentUser } = useContext(AuthContext)
  const [carePlans, setCarePlans] = useState({})
  const [care, getCare] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [TableData, setTableData] = useState([])
  
  useEffect(() => {
    const getData = async () => {
      const data = await getCarePlanQuestions()
      const filter = Object.keys(data).filter((value) => data[value] !== null)
      setCarePlans(data)
      setTableData(filter)
      setLoading(true)
      axios
        .post(API_ROUTE + '/api/user/getCareReceiver', {
          id: currentUser?.id,
          name: "Deepak"
        })
        .then((res) => {
          if (res.data.status) {
            getCare(res.data[0])
            setLoading(false)
          } else {
            console.log(res.data.error)
            setLoading(false)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    getData()
  }, [currentUser?.id])

  const filteredCarePlans = Object.keys(carePlans)
    .filter((value) => carePlans[value] !== null)
    .map((value, index) => (
      <CarePlanCards
        key={index}
        CarePlans={carePlans}
        que={value}
        value={value}
      />
    ))

  if (isLoading) {
    return (
      <>
        <LoadingSVG />
      </>
    )
  }
  return (
    <div>
      <div className={`bg-[url(${process.env.PUBLIC_URL}/images/rec-bg.png)] flex justify-center py-5 border-b-2 border-[#1499FF] relative h-[100px]`}>
        <div className="absolute top-[60%] flex gap-4 justify-center items-center ">
          <img
            className="rounded-full w-20 object-cover h-20 bg-white p-1"
            // src={`${process.env.PUBLIC_URL}/images/mother2.png`}
            src={API_ROUTE + '/' + care?.image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null // prevents looping
              currentTarget.src =
                'https://developers.google.com/static/maps/documentation/maps-static/images/error-image-generic.png'
            }}
            alt=""
          />

          <div>
            <h2 className="text-2xl font-semibold text-center">
              {care?.cr_name}
            </h2>
            <h5 className="flex justify-center gap-5 mt-2 text-[#616161] font-semibold">
              <span>{care?.cr_age} Years old </span>
              <span className="capitalize">{care?.cr_gender} </span>
            </h5>
          </div>
        </div>
      </div>
      <div className="bg-[#DFF0FC] py-10 font-semibold text-left px-6 ">
        <div className="md:max-w-[700px] md:mx-auto">
          <div className="flex items-center justify-between">
            <h3 className="py-4">Care Plan</h3>
            <div className="flex gap-3 cursor-pointer">
              <Link to="/questions">
                <AiOutlineEdit />
              </Link>

              <AiFillPrinter
                onClick={() => {
                  sendPrintMail()
                }}
              />
            </div>
          </div>
          {Object.keys(carePlans).filter(
            (value) => carePlans[value] !== null,
          )[1] !== 'status' ? (
            filteredCarePlans
          ) : (
            <>
              <div> Care Sheet doesn't exist</div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const CarePlanCards = ({ value, CarePlans, que }) => {
  const data = JSON.parse(CarePlans[value])
  const options = !data?.isRadio ? Object.keys(data.checkboxAnswer) : []

  const getCheckboxQuestion = (option) => {
    const question =
      questions[parseInt(que?.replace(/[^0-9]/g, ''), 10) - 1]?.checkboxQuestion

    return question ? question[option] : ''
  }

  return (
    <div className="mt-4">
      <div className="bg-white rounded-md items-center flex flex-col justify-center">
        <h3 className="px-4 py-3 text-center border-b">
          {questions[parseInt(que?.replace(/[^0-9]/g, ''), 10) - 1]?.title}
        </h3>
        <div className="w-full px-4 py-3 grid grid-cols-2 gap-10 items-center">
          <div className="border-2 rounded-md border-[#0566B1] h-20 w-20">
            <img
              src={`${
                process.env.PUBLIC_URL +
                questions[parseInt(que?.replace(/[^0-9]/g, ''), 10) - 1]?.image
              }`}
              alt="sdfs"
              className="w-3/4 h-full mx-auto"
            />
          </div>
          <ol className="list-disc">
            {data?.isRadio && (
              <li className="text-[#414042] font-medium">
                {
                  questions[parseInt(que?.replace(/[^0-9]/g, ''), 10) - 1]
                    ?.radioQuestion
                }
              </li>
            )}
            {options.map((option, index) => (
              <li key={index} className="text-[#414042] font-medium">
                {getCheckboxQuestion(option)}
              </li>
            ))}
          </ol>
        </div>
        {data?.comment && (
          <p className="text-[#414042] text-sm text-center">{data.comment}</p>
        )}
      </div>
      {questions[parseInt(que?.replace(/[^0-9]/g, ''), 10) - 1]?.article ===
      null ? (
        <div
          onClick={() => {
            alert(" Sheet doesn't exist")
          }}
          className="cursor-pointer px-4 py-3 bg-[#0566B1] text-white rounded-b-md text-center"
        >
          {questions[parseInt(que?.replace(/[^0-9]/g, ''), 10) - 1]?.sheet}
        </div>
      ) : (
        <Link
          to={questions[parseInt(que?.replace(/[^0-9]/g, ''), 10) - 1]?.article}
        >
          <div className="cursor-pointer px-4 py-3 bg-[#0566B1] text-white rounded-b-md text-center">
            {questions[parseInt(que?.replace(/[^0-9]/g, ''), 10) - 1]?.sheet}
          </div>
        </Link>
      )}
    </div>
  )
}

export default CarePlans
