import Card from "./Card";

const Affiliates = () => {
    let affiliates_data = [
        {
            brandLogo: "/images/affiliates/1.png",
            brandName: "Samarth Life Management Pvt Ltd.",
            heading: "30K seniors | 350 cities | 1 mission No compromise care for parents anywhere in India",
            content: "Gold-standard critical care, peace of mind support & companionship for elderly at their own home across india, trusted by indian diaspora in 30+ countries since 2016.",
            btnLink: "https://care.samarth.community"
        },
        {
            brandLogo: "/images/affiliates/2.png",
            brandName: "Simplify AgeTech Pvt. Ltd.",
            heading: "Smart Phones Simpli5ied For Seniors",
            content: "Simpli5 is designed to simplify smartphones for seniors, ensuring a seamless and clutter-free digital experience. With features like one-touch emergency",
            btnLink: "https://simpli5.in"
        },
        {
            brandLogo: "/images/affiliates/3.png",
            brandName: "Amigos",
            heading: "Your Trusted Family Assistant!",
            content: "Stay relaxed knowing assistance (Routine, personal task, medical & more) for your family is a call away.\n• Membership based service\n• No joining fees\n• Designated Family Assistant for each family",
            btnLink: "https://amigosfamily.in"
        }
        // Add more affiliate data objects as needed
    ];
    return (
        <>
            {affiliates_data?.length > 0 ?
                <div className="my-10">
                    <h3 className="text-primary-dark font-bold text-4xl my-3 text-center">
                        Our Affiliates
                    </h3>
                    <div className="w-[8%] mx-auto border-b-2 my-16 " style={{ borderBottomColor: '#04518D' }}></div>
                    <div className="py-2 px-2 md:px-2 lg:px-20 flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-5">
                        {affiliates_data.map((affiliate, index) => (
                            <Card
                                key={index}
                                brandLogo={affiliate.brandLogo}
                                brandName={affiliate.brandName}
                                heading={affiliate.heading}
                                content={affiliate.content}
                                btnLink={affiliate.btnLink}
                            />
                        ))}
                    </div>
                </div>
                : ""}

        </>
    );
};

export default Affiliates;
