import React, { useContext, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillGoogleCircle,
} from 'react-icons/ai'
import { AuthContext } from '../../context/AuthContextProvider'
function Login() {
  const { handleLogin, handleGoogleSignIn } = useContext(AuthContext)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      keepLogin: true,
    },

    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      password: Yup.string()
        .required('Password is required')
        .min(4, 'Password must be at least 8 characters long'),
    }),

    onSubmit: (values) => {
      console.log(values)
      handleLogin(values)
    },
  })
  return (
    <div>
      <div>
        <div className="px-6 md:max-w-[1300px] md:mx-auto">
          <h3 className="text-start py-5 ">Please login if registered</h3>
          <div className="w-full ">
            <form
              className="flex flex-col gap-4"
              onSubmit={formik.handleSubmit}
            >
              <div className="w-full">
                <label>Email</label>
                <input
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                  placeholder="email"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>{' '}
              <div className="w-full">
                <label>Password</label>
                <div className="flex">
                  <input
                    className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                    placeholder="password"
                    type={isPasswordVisible ? 'text' : 'password'}
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    className="cursor-pointer  inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-r-md "
                  >
                    {isPasswordVisible ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </span>
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
              <div className="flex justify-end text-[#0566B1]">
                <Link to="/auth/forgot">
                  <h4>Forgot Password ?</h4>
                </Link>
              </div>
              <button className="bg-primary-dark  text-white py-2 rounded-md min-w-[150px]">
                {' '}
                Login
              </button>
              <div className="text-center my-5 ">
                <div>
                  <div className="px-6 sm:px-0 max-w-sm mx-auto ">
                    <button
                      onClick={handleGoogleSignIn}
                      type="button"
                      className="text-white w-full  bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-between dark:focus:ring-[#4285F4]/55 mr-2 mb-2"
                    >
                      <AiFillGoogleCircle size={24} />
                      Login with Google<div></div>
                    </button>
                  </div>
                </div>
                <h4 className="mt-5">
                  <span>
                    Don't have an account? <br></br>
                  </span>
                  <Link to="/auth/register">
                    <span className="text-[#0566B1] font-semibold">
                      Register here{' '}
                    </span>
                  </Link>
                </h4>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
