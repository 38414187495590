import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContextProvider'
function Settings() {
  const { handleDeactivateAccount } = useContext(AuthContext)
  return (
    <div>
      <div className="p-6">
        <h3 className="py-3 font-bold text-left">Settings</h3>
        <div className="flex flex-col gap-3">
          <div className="bg-[#0566B1]  w-full  md:max-w-[300px] p-3 text-white rounded-md">
            <Link to="/settings/reset-password" className="">
              Change Password
            </Link>
          </div>
          <div
            className="bg-[#0566B1]  w-full  md:max-w-[300px] p-3 text-white rounded-md"
            onClick={() => handleDeactivateAccount()}
          >
            Delete Account
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
