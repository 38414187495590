// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import 'swiper/css/free-mode';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Testimonials.css";

// import required modules
import { useEffect, useState } from "react";
import { FreeMode, Navigation } from "swiper/modules";
import { API_ROUTE, IMAGE_API_ROUTE } from "../../api/constant";


const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  useEffect(() => {
    const getTestimonials = async () => {
      const result = await fetch(API_ROUTE + "/api/testimonial/getTestimonials");
      const data = await result.json();
      setTestimonials(data.data);
    }

    getTestimonials();
  }, [])

  return (
    <>
      {testimonials?.length > 0 ? <>
        <h3 className="text-primary-dark font-bold text-2xl my-3 text-center">
          Testimonials
        </h3>
        <div className="px-5 flex justify-center items-center">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            freeMode={true}
            navigation={true}
            pagination={{
              clickable: true,
            }}

            breakpoints={{
              600: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              644: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}

            modules={[FreeMode, Navigation]}
            className="mySwiper"
          >
            {testimonials?.length > 0
              ? testimonials.map((item) => (
                <SwiperSlide key={item.id}>
                  <div className="md:flex block px-10 items-start bg-gray-100 md:pb-0 rounded">
                    <div className="rounded-full mt-5 p-2 bg-white">
                      <img
                        src={`${IMAGE_API_ROUTE}/${item.image}`}
                        className="md:rounded-full rounded"
                        alt=""
                      />
                    </div>
                    <div className="text-left px-5 py-5">
                      <div className="text-justify">{item.comment}</div>
                      <p>
                        <strong className="text-sm">- {item.name}</strong>
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))
              : null}
          </Swiper>
        </div>
      </> : ""}

    </>
  );
};

export default Testimonials;
