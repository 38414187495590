import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_ROUTE } from '../api/constant';
import "./Ckeditor.css";
import Community from "./Community";
const CreatePost = () => {
  let userId = JSON.parse(localStorage.getItem("token")).id;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const editorRef = React.useRef();
  const stripHtml = (html) => {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("userId", userId);
    if (image) formData.append("file", image); // Append the image file to formData
    
    try {
      const response = await axios.post(
        `${API_ROUTE}/api/post/create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );
      toast.success("Post created successfully!");
      setTitle("");
      setDescription("");
      setImage(null); // Reset image state
      if (editorRef.current) {
        editorRef.current.editor.setData("");  // Reset CKEditor content
      }
      navigate('/allpost');
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error("A post with this title already exists.");
      } else {
        toast.error("Error creating post");
      }
      console.error("Error uploading the post", error);
    }
  }
  return (
    <div>
      <Community />
      {/* ----------------------------createpost------------------------------ */}
      <div className="create-post p-4 lg:px-48 lg:pt-14 lg:pb-6">
        <h1
          style={{
            fontSize: "30px",
            fontWeight: "bold",
            paddingBottom: "20px",
          }}
        >
          Create a post
        </h1>
        <form className="post-form " onSubmit={handleSubmit}>
          <h4 className="pb-3">Title</h4>
          <input
            type="text"
            placeholder="Enter the title here"
            className="w-full lg:w-3/4 h-full p-3 pr-10 border rounded-md"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ borderColor: "black" }}
          />
          <h4 className="pt-4">Image</h4>
          <input
            type="file"
            accept="image/*" // Accept only image files
            className="w-full lg:w-3/4 p-3 border rounded-md"
            onChange={(e) => setImage(e.target.files[0])} // Capture the selected image file
          />
          <h4 className="pt-4">Description</h4>
          <div className="w-full lg:w-3/4">
            <CKEditor
              editor={ClassicEditor}
              data=""
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
              }}
            />
          </div>
          {/* -------------------------------ck tool--------------------------- */}
          <button
            className="px-8 py-2 text-white font-bold rounded-lg shadow-md mt-5"
            style={{ backgroundColor: "rgb(4, 81, 141)" }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
export default CreatePost;
