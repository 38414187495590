import axios from "axios";
import { formatDistanceToNow, parseISO } from 'date-fns';
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from "react";
import { BiSolidLike } from "react-icons/bi";
import { FaInfoCircle, FaShare } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { MdInsertComment } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_ROUTE } from "../api/constant";
import './PostCard.css';

const PostCard = ({ postId, username, title, description, image, created_at, like_count = 0, comment_count = 0, showUserDetails, bgcolor, noborder = false, viewBtn = true, hrWidth = "w-full" }) => {
  const [likeCount, setLikeCount] = useState(like_count);
  const [commentCount, setCommentCount] = useState(comment_count);
  const [comment, setComment] = useState("");
  const [report, setReport] = useState("")
  const [showModal, setShowModal] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("token")).id;
  useEffect(() => {
    setLikeCount(like_count);
    setCommentCount(comment_count);
  }, [like_count, comment_count]);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const CommentOpenModal = () => {
    setShowComment(true);
  }
  const CommentCloseModal = () => {
    setShowComment(false);
  }
  const handleCommentSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${API_ROUTE}/api/post/postcomment`, {
        post_id: postId,
        userId: userId,
        replies: comment
      })
      setCommentCount((prevCount) => prevCount + 1);
      toast.success("Comment Posted Successfully!");
      setComment("");
      CommentCloseModal();
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "Comment not posted, something went wrong!")
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(`${API_ROUTE}/api/post/postreport`, {
        post_id: postId,
        userId: userId,
        message: report
      })
      setReport("")
      handleCloseModal();
      toast.success("Report Submitted Successfully")
    } catch (error) {
      toast.error("Error Submitting report")
    }
  };
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };
  const handleReportChange = (event) => {
    setReport(event.target.value);
  };
  const toggleLike = async () => {
    try {
      const response = await axios.post(`${API_ROUTE}/api/post/postlike`,
        {
          post_id: postId,
          userId: userId
        },
      );
      if (response.data.liked) {
        setLikeCount((prev) => (isNaN(prev) ? 1 : prev + 1));
      }
      else {
        setLikeCount((prev) => (isNaN(prev) ? 0 : prev - 1));
      }
    }
    catch (error) {
      console.error("Error toggling like:", error);
    }
  };
  const handleViewMore = () => {
    navigate(`/postdetails/${postId}`)
  }
  const handleShareClick = () => {
    const currentUrl = `${window.location.origin}/postdetails/${postId}`
    navigator.clipboard.writeText(currentUrl).then(() => {
      toast.success("Link copied to clipboard!");
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };
  return (
    <div className="w-100 mx-auto py-4">
      <div className={`${noborder ? "" : "rounded-2xl"} ${bgcolor || "bg-[#D7ECFC]"}`} style={{
        boxShadow: '1px solid rgba(4, 81, 141, 0.31)'
      }}>
        <div className={"flex justify-between items-center px-3 pt-4 " + (showUserDetails ? "pb-4" : "pb-0")}>
          {showUserDetails ? (
            <div className="flex items-center gap-3 md:gap-5">
              <div className="user-icon flex items-center justify-center text-lg text-[#04518D] font-bold capitalize rounded-full h-10 w-10 bg-white shadow-md text-[#04518D]">
                {username[0]}
              </div>
              <h2 className="font-normal text-lg text-[#04518D] capitalize">
                {username}
              </h2>
            </div>
          ) : (
            <h1 className="text-[#04518D] text-2xl font-semibold pl-2 capitalize">
              {title}
            </h1>
          )}
          <p className="text-sm text-[#0481A2] capitalize font-normal">
            {created_at ? formatDistanceToNow(parseISO(created_at), { addSuffix: true }) : 'Date not available'}
          </p>
        </div>
        <hr className={`bg-[#04518D4F] h-[1.5px] ${hrWidth} block`} />
        <div className={"px-4 pb-4 " + (showUserDetails ? "pt-4" : "pt-0")}>
          {showUserDetails && (
            <>
              <h2 className="text-[#04518D] text-2xl font-semibold capitalize">
                {title}
              </h2>
            </>
          )}
          <div className={`flex flex-col md:flex-row py-4 ${image ? "gap-4" : ""}`}>
            {image && (
              <img
                src={API_ROUTE + image}
                alt="Post"
                className="py-1 h-auto rounded-2xl md:w-52 lg:w-56 md:h-52 lg:h-56"
              />
            )}
            <p className=" pb-1 pt-0  text-[#3F3F3F] font-normal max-h-56 overflow-auto flex-grow scrollable">
              <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
              />
            </p>
          </div>
          <div className="flex justify-between items-center ">
            <div className="flex items-center">
              <div
                className="relative inline-flex items-center text-2xl text-red mr-1 cursor-pointer"
                onClick={toggleLike}
              >
                <BiSolidLike
                  style={{ color: "#04518D", height: "23px", width: "23px" }}
                />
                <div className="absolute -top-2 -right-2 flex items-center justify-center w-4 h-4 bg-white text-xs text-red rounded-full" style={{ color: 'red' }}>
                  {isNaN(likeCount) ? 0 : likeCount}
                </div>
              </div>
              <div
                className="relative inline-flex items-center text-2xl text-[#0481A2] mx-1 cursor-pointer"
                onClick={CommentOpenModal}
              >
                <MdInsertComment
                  style={{ color: "#04518D", height: "23px", width: "23px" }}
                />
                <div className="absolute -top-2 -right-2 flex items-center justify-center w-4 h-4 bg-white text-xs rounded-full" style={{ color: 'red' }}>
                  {commentCount}
                </div>
              </div>
              <div className="text-2xl text-[#0481A2] mx-1 cursor-pointer" onClick={handleShareClick}>
                <FaShare
                  style={{ color: "#04518D", height: "23px", width: "23px" }}
                />
              </div>
              <div
                className="text-2xl text-[#0481A2] mx-1 cursor-pointer"
                onClick={handleOpenModal}
              >
                <FaInfoCircle
                  style={{ color: "#04518D", height: "23px", width: "23px" }}
                />
              </div>
            </div>
            {viewBtn ? (<button className="bg-[#0566B1] text-white font-bold shadow-md rounded-md px-8 py-2 text-sm flex" onClick={handleViewMore}>
              View More   <FaArrowRightLong style={{ margin: '4px' }} />
            </button>) : ""}
          </div>
        </div>
        {showModal && (
          <div className="fixed top-0 left-0 right-0 flex justify-center items-start p-4 bg-black bg-opacity-50 z-50 h-full">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full mt-16">
              <div className="flex justify-between">
                <h2 className="text-2xl font-bold text-[#04518D] mb-4">
                  Report a Post
                </h2>
                <IoMdClose onClick={handleCloseModal} style={{ height: '24px', width: '24px' }} />
              </div>
              <form onSubmit={handleSubmit}>
                <textarea
                  className="w-full h-32 p-2 border border-black-300 rounded-lg mb-4 box-shadow: 0px 4px 4px 0px #00000040;
"
                  placeholder="Describe the issue" value={report} onChange={handleReportChange} style={{
                    boxShadow: '0px 4px 4px 0px #00000040',
                    border: '1px solid #616161'
                  }}
                  required
                ></textarea>
                <div className="flex ">
                  <button
                    type="submit"
                    className="px-4 py-2 text-white font-bold  bg-[#0566B1] mr-2 text-sm w-32" style={{
                      boxShadow: " 0px 5px 6px 0px #00000026", borderRadius: '5px'
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {showComment && (
          <div className="fixed top-0 left-0 right-0 flex justify-center items-start p-4 bg-black bg-opacity-50 z-50 h-full">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full mt-16">
              <div className="flex justify-between">
                <h2 className="text-2xl font-bold text-[#04518D] mb-4">
                  Write a Comment
                </h2>
                <IoMdClose onClick={CommentCloseModal} style={{ height: '24px', width: '24px' }} />
              </div>
              <form onSubmit={handleCommentSubmit}>
                <textarea
                  className="w-full h-32 p-2 border border-black-300 rounded-lg mb-4"
                  placeholder="Say something..." value={comment} onChange={handleCommentChange}
                  required
                ></textarea>
                <div className="flex ">
                  <button
                    type="submit"
                    className="px-4 py-2 text-white font-bold  bg-[#0566B1] mr-2"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default PostCard;
