//import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material'
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  //const [value, setValue] = React.useState(0)

  let LINKS = [
    {
      icon:
        location.pathname === "/"
          ? process.env.PUBLIC_URL + "/images/footer/home-dark.png"
          : process.env.PUBLIC_URL + "/images/footer/home.png",
      title: (
        <div
          className={` ${
            location.pathname === "/" ? "text-[#0566B1]" : "text-white"
          } lg:text-xs text-[11px] mt-2 `}
        >
          Home
        </div>
      ),
      link: "/",
      id: 1,
    },
    {
      icon:
        location.pathname === "/plans"
          ? process.env.PUBLIC_URL + "/images/footer/user-dark.png"
          : process.env.PUBLIC_URL + "/images/footer/user.png",
      title: (
        <div
          className={` ${
            location.pathname === "/plans" ? "text-[#0566B1]" : "text-white"
          } lg:text-xs text-[11px] mt-2 `}
        >
          CARE PLAN
        </div>
      ),
      link: "/plans",
      id: 4,
    },
    {
      icon:
        location.pathname === "/sheets"
          ? process.env.PUBLIC_URL + "/images/footer/sheets-dark.png"
          : process.env.PUBLIC_URL + "/images/footer/sheets.png",
      title: (
        <div
          className={` ${
            location.pathname === "/sheets" ? "text-[#0566B1]" : "text-white"
          } lg:text-xs text-[11px] mt-2 `}
        >
          CARE SHEETS
        </div>
      ),
      link: "/sheets",
      id: 2,
    },
    {
      icon:
        location.pathname === "/videos"
          ? process.env.PUBLIC_URL + "/images/footer/video-dark.png"
          : process.env.PUBLIC_URL + "/images/footer/video.png",
      title: (
        <div
          className={` ${
            location.pathname === "/videos" ? "text-[#0566B1]" : "text-white"
          } lg:text-xs text-[11px] mt-2 `}
        >
          CARE VIDEOS
        </div>
      ),
      link: "/videos",
      id: 3,
    }
    
  ];

  return (
    <div className="w-full">
      <div className="fixed bottom-0  left-0 z-50 w-full h-16 bg-[#0566B1] border-t border-gray-200">
        <div className="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
          {LINKS.map((link) => (
            <div key={link.id}>
              <NavLink
                className={`flex flex-col items-center justify-center group ${
                  location.pathname === link.link
                    ? "bg-[#B7DFFD]"
                    : "bg-transparent"
                } `}
                to={link.link}
              >
                <button
                  type="button"
                  className="inline-flex flex-col items-center justify-center pt-[7px]"
                >
                  <img
                    src={link.icon}
                    alt={link.icon}
                    className="w-5 h-5 sm:w-7 sm:h-7 "
                  />

                  {link.title}
                </button>
              </NavLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Footer;
