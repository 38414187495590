import { Route, Routes } from "react-router-dom";
import "./App.css";
import ProtectedRoute from "./ProtectedRoute";
import Footer from "./components/Footer/Footer";
import Sidebar from "./components/Sidebar/Sidebar";
import Acknowlegement from "./pages/Acknowledgement";
import ArticlePage from "./pages/ArticlePage";
import Forgot from "./pages/Auth/Forgot";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import CareBookCategory from "./pages/Bookmarks/CareBookCategory";
import CareBookVideoCategory from "./pages/Bookmarks/CareBookVideoCategory";
import CareBookVideos from "./pages/Bookmarks/CareBookVideos";
import CareBookMarkCategory from "./pages/CareBookMarkCategory";
import CareCategory from "./pages/CareCategory";
import CarePlans from "./pages/CarePlans";
import CareSheet from "./pages/CareSheet";
import CareVideo from "./pages/CareVideo";
import CareVideoCategory from "./pages/CareVideoCategory";
import Disclaimer from "./pages/Disclaimer";
import FeedBackForm from "./pages/FeedBackForm";
import Home from "./pages/Home";
import OurTeam from "./pages/OurTeam";
import Privacy from "./pages/Privacy";
import CareGiver from "./pages/Profile/CareGiver";
import CareReceiver from "./pages/Profile/CareReceiver";
import Questions from "./pages/Question/Questions";
import ResetPassword from "./pages/Settings/ResetPassword";
import Settings from "./pages/Settings/Settings";

import CreatePost from "./pages/CreatePost";

import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.css";
import AllPost from "./pages/AllPost";
import MyPost from "./pages/MyPost";
import PostDetails from "./pages/PostDetails";

function App() {

  return (
    <>
      <Sidebar />
      <div className="pb-20 pt-[100px]">
        <Routes>
          <Route index element={<Home />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/acknowlegement" element={<Acknowlegement />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/auth">
            <Route path="login" element={<Login />} />
            <Route path="forgot" element={<Forgot />} />
            <Route path="register" element={<Register />} />
          </Route>
            <Route path="sheets">
              <Route index element={<CareSheet />} />
              <Route path=":category" element={<CareCategory />} />
              <Route path=":category/:articleId" element={<ArticlePage />} />
            </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/profile">
              <Route path="receiver" element={<CareReceiver />} />
              <Route path="giver" element={<CareGiver />} />
            </Route>
            <Route path="/videos">
              <Route index element={<CareVideo />} />
              <Route path=":category" element={<CareVideoCategory />} />
            </Route>
            <Route path="/plans">
              <Route index element={<CarePlans />} />
            </Route>
            <Route path="/bookmark">
              <Route path="sheets" element={<CareBookCategory />} />
              <Route
                path="sheet/:category"
                element={<CareBookMarkCategory />}
              />
              <Route path="videos" element={<CareBookVideos />} />
              <Route
                path="video/:category"
                element={<CareBookVideoCategory />}
              />
            </Route>
            <Route path="/feedback" element={<FeedBackForm />} />
            <Route path="/settings">
              <Route index element={<Settings />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
            <Route path="/questions" element={<Questions />} />
   
            <Route path="/allpost" element={<AllPost/>}/>
            <Route path="/mypost" element={<MyPost />}/>
            <Route path="/createpost" element={<CreatePost/>}/>
            <Route path="/postdetails/:postId" element={<PostDetails/>}/>
          </Route>
        </Routes>
      </div>
      <Footer />
      <ToastContainer position="top-center"/>
    </>
  );
}

export default App;
