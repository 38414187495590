// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-content{
    height: 140px;
}

/* .ck-icon.ck-reset_all-excluded,  */
.ck-powered-by__label{
    /* opacity: 0!important; */
    display: none!important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Ckeditor.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA,qCAAqC;AACrC;IACI,0BAA0B;IAC1B,uBAAuB;AAC3B","sourcesContent":[".ck-content{\n    height: 140px;\n}\n\n/* .ck-icon.ck-reset_all-excluded,  */\n.ck-powered-by__label{\n    /* opacity: 0!important; */\n    display: none!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
