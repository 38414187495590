import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

export default function Modal({ children, title }) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <button
        onClick={openModal}
        className=" text-sm px-6 py-2 rounded-full bg-primary-dark text-white flex items-center gap-3    "
      >
        <span>
          <svg
            width="25"
            height="31"
            viewBox="0 0 25 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.4741 28C20.4741 28.7877 20.4741 29.5447 20.4741 30.3024C20.4741 30.8512 20.3272 31 19.7872 31C13.4192 31 7.05185 31 0.683853 31C0.145669 31 0 30.8506 0 30.2986C0 21.4343 0 12.57 0 3.70575C0 3.15126 0.145669 3.00061 0.679586 2.99998C1.64442 2.99998 2.60924 2.99998 3.57407 2.99998C3.67281 2.99998 3.77155 2.99998 3.90015 2.99998C3.90015 2.21232 3.89954 1.45529 3.90015 0.697641C3.90076 0.14878 4.04704 0 4.58705 0C10.955 0 17.3224 0 23.6904 0C24.2286 0 24.3743 0.14878 24.3743 0.701392C24.3743 9.56568 24.3743 18.43 24.3743 27.2943C24.3743 27.8494 24.2292 27.9994 23.6947 28C22.7299 28.0006 21.765 28 20.8002 28C20.7015 28 20.6027 28 20.4741 28ZM19.4806 29.9885C19.4806 22.9815 19.4806 15.9995 19.4806 8.99869C19.3496 8.99869 19.2393 8.99869 19.129 8.99869C17.8393 8.99869 16.5496 8.99932 15.2599 8.99869C14.7882 8.99869 14.6266 8.82991 14.6266 8.33981C14.626 7.00641 14.6266 5.67364 14.6266 4.34025C14.6266 4.23085 14.6266 4.12146 14.6266 4.01394C10.0548 4.01394 5.52933 4.01394 0.989818 4.01394C0.989818 12.6782 0.989818 21.3268 0.989818 29.9892C7.15668 29.9885 13.3071 29.9885 19.4806 29.9885ZM23.3899 1.02021C17.2048 1.02021 11.0471 1.02021 4.89729 1.02021C4.89729 1.69159 4.89729 2.3411 4.89729 3.00061C5.04356 3.00061 5.16485 3.00061 5.28675 3.00061C8.49574 3.00061 11.7047 3.00373 14.9131 2.9956C15.2014 2.99498 15.4104 3.08812 15.6104 3.29442C17.156 4.89036 18.7072 6.48068 20.2651 8.06475C20.4241 8.22666 20.4784 8.38669 20.4784 8.60986C20.4729 14.6092 20.4741 20.6079 20.4741 26.6072C20.4741 26.7291 20.4741 26.851 20.4741 26.9836C21.4627 26.9836 22.4208 26.9836 23.3899 26.9836C23.3899 18.3231 23.3899 9.68195 23.3899 1.02021ZM18.6402 7.98348C17.6424 6.96015 16.6075 5.89869 15.6232 4.88849C15.6232 5.86243 15.6232 6.9264 15.6232 7.98348C16.6764 7.98348 17.7046 7.98348 18.6402 7.98348Z"
              fill="white"
            />
            <path
              d="M11.6984 21.4855C12.8827 21.6155 13.6439 22.4107 13.6482 23.4865C13.6531 24.5799 12.8967 25.3807 11.6984 25.5163C11.6984 26.1127 11.699 26.7141 11.6984 27.3148C11.6978 27.9149 11.44 28.1081 10.8896 27.9199C9.12332 27.3161 7.36249 26.6959 5.58765 26.1196C5.21403 25.9983 4.79165 26.0189 4.3906 26.0027C3.56839 25.9695 2.89246 25.6463 2.41462 24.9543C1.85693 24.1473 1.78257 23.2709 2.21227 22.3888C2.64806 21.4943 3.39225 20.9742 4.36195 21.0204C5.45539 21.0723 6.39218 20.5935 7.37895 20.2809C8.56746 19.9039 9.74074 19.4751 10.9213 19.0719C11.4321 18.8975 11.6978 19.1 11.6984 19.6583C11.699 20.2609 11.6984 20.8641 11.6984 21.4855ZM10.7117 26.7997C10.7117 24.5755 10.7117 22.4038 10.7117 20.2303C10.6781 20.2303 10.6568 20.2246 10.6385 20.2309C9.09346 20.7566 7.54717 21.2805 6.00637 21.8187C5.9314 21.845 5.85582 21.9994 5.8546 22.095C5.84363 23.0314 5.85156 23.9679 5.84424 24.9049C5.84302 25.0769 5.88935 25.1594 6.05818 25.2144C6.77128 25.4463 7.4783 25.6976 8.18836 25.9402C9.02214 26.2246 9.85593 26.5084 10.7117 26.7997ZM4.86113 22.0194C4.15412 21.9312 3.54645 22.0669 3.15089 22.7108C2.82298 23.244 2.83212 23.8022 3.17039 24.3242C3.57083 24.9425 4.17118 25.0737 4.86174 24.9806C4.86113 23.9966 4.86113 23.0227 4.86113 22.0194ZM11.7198 22.5195C11.7198 23.1765 11.7198 23.8329 11.7198 24.4905C12.3018 24.438 12.6876 24.0191 12.6755 23.4747C12.6627 22.9421 12.2519 22.5301 11.7198 22.5195Z"
              fill="white"
            />
            <path
              d="M1.95789 12.4793C1.95789 12.153 1.95789 11.8523 1.95789 11.5266C7.46588 11.5266 12.9708 11.5266 18.5002 11.5266C18.5002 11.8279 18.5002 12.1436 18.5002 12.4793C12.9977 12.4793 7.4927 12.4793 1.95789 12.4793Z"
              fill="white"
            />
            <path
              d="M18.5163 13.5208C18.5163 13.8477 18.5163 14.1484 18.5163 14.4741C13.0077 14.4741 7.50211 14.4741 1.97339 14.4741C1.97339 14.1715 1.97339 13.8558 1.97339 13.5208C7.47712 13.5208 12.9821 13.5208 18.5163 13.5208Z"
              fill="white"
            />
            <path
              d="M1.96826 15.5149C7.48113 15.5149 12.9794 15.5149 18.5008 15.5149C18.5008 15.8287 18.5008 16.1444 18.5008 16.4795C12.9971 16.4795 7.4915 16.4795 1.96826 16.4795C1.96826 16.1675 1.96826 15.8593 1.96826 15.5149Z"
              fill="white"
            />
            <path
              d="M3.90676 10.4914C3.55874 10.4914 3.26557 10.4914 2.9529 10.4914C2.94315 10.4214 2.92486 10.3526 2.92608 10.2845C2.94437 9.40183 2.91694 8.51478 2.99983 7.63897C3.06871 6.90945 3.26618 6.19306 3.41063 5.47166C3.47707 5.14034 3.63249 5.00532 3.95308 5.00157C4.39923 4.99657 4.84599 4.99719 5.29275 5.00157C5.62066 5.00469 5.78156 5.13597 5.84678 5.45853C6.0089 6.2612 6.23015 7.05823 6.30634 7.86965C6.38618 8.72419 6.32401 9.59249 6.32401 10.4764C6.01012 10.4764 5.70294 10.4764 5.37259 10.4764C5.37259 9.83004 5.37259 9.18866 5.37259 8.5229C4.88073 8.5229 4.4102 8.5229 3.90615 8.5229C3.90676 9.16428 3.90676 9.81379 3.90676 10.4914ZM5.2257 7.48457C5.23119 7.45081 5.23546 7.44018 5.23424 7.43081C5.17512 7.10512 5.11417 6.77943 5.05505 6.45373C4.97155 5.99364 4.85818 5.90987 4.41508 6.00427C4.36144 6.01552 4.28647 6.07678 4.27611 6.12679C4.18225 6.57188 4.10119 7.01947 4.0122 7.48519C4.42422 7.48457 4.81612 7.48457 5.2257 7.48457Z"
              fill="white"
            />
            <path
              d="M15.4506 28.3252C15.2336 28.1014 15.0154 27.8776 14.7814 27.6363C15.94 26.5073 16.5702 25.1395 16.5715 23.5061C16.5727 21.872 15.9467 20.5029 14.7814 19.3677C15.0246 19.1427 15.2537 18.9301 15.4859 18.7151C18.1781 21.0999 18.3042 25.7334 15.4506 28.3252Z"
              fill="white"
            />
            <path
              d="M7.32617 10.4764C7.32617 10.1507 7.32617 9.84313 7.32617 9.51807C9.43197 9.51807 11.525 9.51807 13.6332 9.51807C13.6332 9.83751 13.6332 10.1457 13.6332 10.4764C11.5408 10.4764 9.44782 10.4764 7.32617 10.4764Z"
              fill="white"
            />
            <path
              d="M14.4915 20.6903C15.9683 22.0231 15.964 25.0249 14.4787 26.3171C14.2605 26.0945 14.0417 25.8707 13.8241 25.6482C14.8675 24.4636 14.9364 22.6263 13.821 21.3417C14.0386 21.1304 14.2574 20.9179 14.4915 20.6903Z"
              fill="white"
            />
          </svg>
        </span>
        <span>
          Transcript of video<br></br> What is {title}?
        </span>
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 flex justify-between "
                  >
                    <span>{title} Transcript</span>
                    <span onClick={closeModal}>Close</span>
                  </Dialog.Title>
                  <div className="mt-4">{children}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
