import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useRestAPI from '../api'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import { useNavigate } from 'react-router-dom'
function FeedBackForm() {
  useEffect(() => {
    tippy('#info', {
      content: 'Fill The feedback form details',
    })
  }, [])
  const { feedbackForm } = useRestAPI()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      mobileNumber: '',
      role: '',
      hear: '',
      findUseful: '',
      suggestion: '',
      rate: 4,
      isPublished: true,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name  is required'),
      email: Yup.string().required('Email is required'),
      role: Yup.string().required('Role is required'),
      findUseful: Yup.string().required('Find useful is required'),
      suggestion: Yup.string().required('Suggestion is required'),
      mobileNumber: Yup.string().matches(
        /^[0-9]{10}$/,
        'Mobile number must be 10 digits',
      ),
    }),
    onSubmit: (values) => {
      console.log(values)
      let data = {
        ...values,
        phone: values.mobileNumber,
        useful: values.findUseful,
        rating: values.rate,
        publish: values.isPublished,
      }
      feedbackForm(data)
    },
  })
  return (
    <div>
      <div>
        <div className="px-6  md:max-w-[1300px] md:mx-auto">
          <h3 className="text-start py-5 flex  items-center  gap-2">
            <span>Feedback form</span>{' '}
            {/* <span id="info">
              <AiOutlineInfoCircle />
            </span> */}
          </h3>
          <div className="w-full ">
            <form
              className="flex flex-col gap-4"
              onSubmit={formik.handleSubmit}
            >
              <div className="w-full">
                <div className="text-left  font-semibold">Name </div>
                <input
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                  //placeholder"Name"
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>{' '}
              <div className="w-full">
                <div className="text-left  font-semibold">Email </div>
                <input
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                  //placeholder"Email"
                  type="text"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>{' '}
              <div className="w-full">
                <div className="text-left  font-semibold">Mobile Number </div>
                <input
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                  //placeholder"Mobile Number "
                  type="number"
                  name="mobileNumber"
                  value={formik.values.mobileNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                <div className="text-start text-red-600 text-sm ">
                  {formik.errors.mobileNumber}
                </div>
              ) : null}
              <div className="w-full">
                <div className="text-left  font-semibold">
                  What is your role ?
                </div>
                <input
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                  //placeholder"Role"
                  type="text"
                  name="role"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.role && formik.errors.role ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.role}
                  </div>
                ) : null}
              </div>
              <div className="w-full">
                <div className="text-left  font-semibold">
                  How did you hear about us?
                </div>
                <input
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                  //placeholder"How did you hear about us?"
                  type="text"
                  name="hear"
                  value={formik.values.hear}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.hear && formik.errors.hear ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.hear}
                  </div>
                ) : null}
              </div>
              <div className="w-full">
                <div className="text-left  font-semibold">
                  What did you find most useful in the Prem Seva app?
                </div>
                <input
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                  //placeholder""
                  type="text"
                  name="findUseful"
                  value={formik.values.findUseful}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.findUseful && formik.errors.findUseful ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.findUseful}
                  </div>
                ) : null}
              </div>{' '}
              <div className="w-full">
                <div className="text-left  font-semibold">
                  What are your suggestions for improving the Prem Seva app?
                </div>
                <input
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                  //placeholder""
                  type="text"
                  name="suggestion"
                  value={formik.values.suggestion}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.suggestion && formik.errors.suggestion ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.suggestion}
                  </div>
                ) : null}
              </div>
              <button className="bg-primary-dark  text-white py-2 rounded-md min-w-[150px]">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeedBackForm
