import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthContext } from './context/AuthContextProvider'

function ProtectedRoute() {
  const { currentUser } = useContext(AuthContext)
  const isAuthenticated = localStorage.getItem('token')
  if (!JSON.parse(isAuthenticated)) {
    return <Navigate to="/auth/login" replace />
  }
  return <Outlet />
}

export default ProtectedRoute
