import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import React, { useContext } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiIdCard } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { CiCircleInfo } from "react-icons/ci";
import { FaUser } from "react-icons/fa";
import { IoIosArrowDown, IoMdSettings } from "react-icons/io";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { PiMedalLight } from "react-icons/pi";
import { RiTeamLine } from "react-icons/ri";
import { TbDiscountCheck } from "react-icons/tb";
import { VscTasklist } from "react-icons/vsc";

import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FiEdit, FiPlay } from "react-icons/fi";
import {
  MdBookmarkAdded,
  MdOutlineExitToApp,
  MdOutlinePermContactCalendar,
} from "react-icons/md";
import { RiHandHeartLine } from "react-icons/ri";
import { TbMessages } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContextProvider";
function Sidebar() {
  const [isOpenDrawer, setOpenDrawer] = React.useState(false);

  return (
    <div>
      <div className="z-50 bg-primary rounded-b-3xl drop-shadow-xl fixed left-0 right-0">
        <div className="flex justify-between items-center lg:px-6 px-3">
          <div className="flex items-center  py-3 pt-6">
            <span className="bg-white rounded-full p-3">
              <img src={process.env.PUBLIC_URL + "/images/logo.svg"} alt="" />
            </span>
            <div className="font-semibold px-2">
              <h2 className="text-primary-dark lg:text-2xl text-lg">
                PREM SEVA
              </h2>
              <h3 className="text-primary-dark text-sm ">
                Supporting your caregiving
              </h3>
            </div>
          </div>

          <div className="flex justify-between items-center lg:w-1/4">
            {/* LINKS */}
            <div className="hidden lg:flex items-center lg:w-3/4 gap-4">
              <h4 style={{ color: "rgb(5 102 177 / var(--tw-bg-opacity)" }}>
                Follow Us On
              </h4>
              <div className="lg:flex">
                <a href="https://www.facebook.com/profile.php?id=61559405760394">
                  <img
                    width={32}
                    height={32}
                    src={process.env.PUBLIC_URL + "/images/facebook.svg"}
                  />
                </a>
                <a href="https://www.instagram.com/prem_seva">
                  <img
                    width={32}
                    height={32}
                    src={process.env.PUBLIC_URL + "/images/instagram.svg"}
                  />
                </a>
                <a href="https://www.linkedin.com/company/75642220/admin/dashboard/">
                  <img
                    width={32}
                    height={32}
                    src={process.env.PUBLIC_URL + "/images/linkedin.svg"}
                  />
                </a>
              </div>
            </div>

            <div onClick={() => setOpenDrawer(true)}>
              <span className="cursor-pointer">
                <img
                  src={process.env.PUBLIC_URL + "/images/burger.svg"}
                  alt=""
                />
              </span>
            </div>
          </div>
        </div>

        <SwipeableDrawer
          anchor="right"
          open={isOpenDrawer}
          onClose={() => setOpenDrawer(false)}
          onOpen={() => setOpenDrawer(true)}
        >
          <div className="w-[350px] ">
            <div>
              <div className="flex items-center  justify-between  border-b-2 p-6">
                <div className="flex items-center gap-4">
                  <span className="bg-white rounded-full p-3 drop-shadow-xl">
                    <img
                      src={process.env.PUBLIC_URL + "/images/logo.svg"}
                      alt=""
                    />
                  </span>

                  <span className="text-2xl font-semibold text-[#414042]">
                    PremSeva
                  </span>
                </div>
                <AiFillCloseCircle
                  onClick={() => setOpenDrawer(false)}
                  size={30}
                  className="cursor-pointer"
                  color="#616161"
                />
              </div>
            </div>
            <div className="p-6">
              <NestedDrawer setOpenDrawer={setOpenDrawer} />
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    </div>
  );
}

const NestedDrawer = ({ setOpenDrawer }) => {
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const { handleLogout, currentUser } = useContext(AuthContext);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      {currentUser ? (
        <>
          <div>
            <ListItemButton onClick={handleClick}>
              <ListItemIcon>{<FaUser size={20} />}</ListItemIcon>
              <ListItemText primary="Profile" />
              {open ? <IoIosArrowDown /> : <IoIosArrowDown />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/profile/giver" onClick={() => setOpenDrawer(false)}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <BiIdCard size={24} />
                    </ListItemIcon>
                    <ListItemText primary="Caregiver" />
                  </ListItemButton>
                </Link>

                <Link
                  to="/profile/receiver"
                  onClick={() => setOpenDrawer(false)}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <CgProfile size={24} />
                    </ListItemIcon>
                    <ListItemText primary="Care Recevier" />
                  </ListItemButton>
                </Link>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <VscTasklist size={24} />
                  </ListItemIcon>
                  <ListItemText primary="Subscription" />
                </ListItemButton>
              </List>
            </Collapse>
          </div>
          <div>
            <ListItemButton onClick={handleClick}>
              <ListItemIcon>{<MdBookmarkAdded size={25} />}</ListItemIcon>
              <ListItemText primary="Bookmarks" />
              {open ? <IoIosArrowDown /> : <IoIosArrowDown />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link
                  to="/bookmark/sheets"
                  onClick={() => setOpenDrawer(false)}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <RiHandHeartLine size={24} />
                    </ListItemIcon>
                    <ListItemText primary="Care sheets" />
                  </ListItemButton>
                </Link>
                <Link
                  to="/bookmark/videos"
                  onClick={() => setOpenDrawer(false)}
                >
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <FiPlay size={24} />
                    </ListItemIcon>
                    <ListItemText primary="Care videos" />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
          </div>
          <div>
            <Link to="/feedback" onClick={() => setOpenDrawer(false)}>
              <ListItemButton>
                <ListItemIcon>
                  <FiEdit size={24} />
                </ListItemIcon>
                <ListItemText primary="Feedback" />
              </ListItemButton>{" "}
            </Link>
            <ListItemButton onClick={() => {
              setOpenDrawer(false);
              navigate("/allpost");
            }}>
              <ListItemIcon>
                <TbMessages size={24} />
              </ListItemIcon>
              <ListItemText primary="Our Community" />
            </ListItemButton>{" "}
            <a
              href="mailto:premsevateam@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItemButton>
                <ListItemIcon>
                  <MdOutlinePermContactCalendar size={24} />
                </ListItemIcon>
                <ListItemText primary="Contact Us" />
              </ListItemButton>{" "}
            </a>
            <ListItemButton
              onClick={() => {
                setOpenDrawer(false);
                navigate("/settings");
              }}
            >
              <ListItemIcon>
                <IoMdSettings size={24} />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>{" "}
            <ListItemButton
              onClick={() => {
                setOpenDrawer(false);
                handleLogout();
              }}
            >
              <ListItemIcon>
                <BiIdCard size={24} />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                setOpenDrawer(false);
                navigate("/privacy");
              }}
            >
              <ListItemIcon>
                <IoShieldCheckmarkOutline size={24} />
              </ListItemIcon>
              <ListItemText primary="Privacy Policy" />
            </ListItemButton>
          </div>
          <div>
            <ListItemButton onClick={handleClick}>
              <ListItemIcon>{<CiCircleInfo size={25} />}</ListItemIcon>
              <ListItemText primary="About Us" />
              {open ? <IoIosArrowDown /> : <IoIosArrowDown />}
            </ListItemButton>



            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/disclaimer" onClick={() => setOpenDrawer(false)}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <TbDiscountCheck size={24} />
                    </ListItemIcon>
                    <ListItemText primary="Disclaimer" />
                  </ListItemButton>
                </Link>
                <Link to="/acknowlegement" onClick={() => setOpenDrawer(false)}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <PiMedalLight size={24} />
                    </ListItemIcon>
                    <ListItemText primary="Acknowlegements" />
                  </ListItemButton>
                </Link>
                <Link to="/our-team" onClick={() => setOpenDrawer(false)}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <RiTeamLine size={24} />
                    </ListItemIcon>
                    <ListItemText primary="Our Team" />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
          </div>
        </>
      ) : (
        <>
          <ListItemButton
            onClick={() => {
              setOpenDrawer(false);
              navigate("/auth/login");
            }}
          >
            <ListItemIcon>
              <MdOutlineExitToApp size={24} />
            </ListItemIcon>
            <ListItemText primary="Login" />
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              setOpenDrawer(false);
              navigate("/auth/register");
            }}
          >
            <ListItemIcon>
              <CgProfile size={24} />
            </ListItemIcon>
            <ListItemText primary="Register" />
          </ListItemButton>

          <div>
            <ListItemButton onClick={handleClick}>
              <ListItemIcon>{<CiCircleInfo size={25} />}</ListItemIcon>
              <ListItemText primary="About Us" />
              {open ? <IoIosArrowDown /> : <IoIosArrowDown />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/disclaimer" onClick={() => setOpenDrawer(false)}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <TbDiscountCheck size={24} />
                    </ListItemIcon>
                    <ListItemText primary="Disclaimer" />
                  </ListItemButton>
                </Link>
                <Link to="/acknowlegement" onClick={() => setOpenDrawer(false)}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <PiMedalLight size={24} />
                    </ListItemIcon>
                    <ListItemText primary="Acknowlegements" />
                  </ListItemButton>
                </Link>
                <Link to="/our-team" onClick={() => setOpenDrawer(false)}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemIcon>
                      <RiTeamLine size={24} />
                    </ListItemIcon>
                    <ListItemText primary="Our Team" />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
          </div>

          <ListItemButton
            onClick={() => {
              setOpenDrawer(false);
              navigate("/privacy");
            }}
          >
            <ListItemIcon>
              <IoShieldCheckmarkOutline size={24} />
            </ListItemIcon>
            <ListItemText primary="Privacy Policy" />
          </ListItemButton>

        </>
      )}
    </>
  );
};

export default Sidebar;
