import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Card = ({ brandLogo, brandName, heading, content, btnLink }) => {
    return (
        <div className="w-full md:w-1/2 lg:w-1/2 shadow-lg rounded-2xl bg-[#d7ecfc]">
            <div className="flex justify-center align-items-end bg-[#0566B1] p-3  md:p-3 gap-12 h-[8.4rem] rounded-2xl">
                <div className="w-1/4">
                    <div className="rounded-full bg-white w-28 h-28 flex justify-center align-items-center shadow-lg">
                        <img src={brandLogo} alt={brandName + " Logo"} style={{ objectFit: "contain", transform: "scale(0.85)" }} />
                    </div>
                </div>
                <div className="w-3/4 flex flex-col justify-center">
                    <p className="text-white lg:text-md font-bold">{heading}</p>
                </div>
            </div>
            <div className="flex flex-col justify-between px-4 pt-5  pb-5 h-60 text-md">
                <p>{content.split('\n').map((item, index) => (
                    <span key={index}>
                        {item}
                        {index < content.split('\n').length - 1 && <br />}
                    </span>
                ))}</p>
                <div className="flex flex-row-reverse">
                    <Link to={btnLink}>
                        <button className="bg-[#0566B1] text-white font-bold shadow-md rounded-md px-8 py-2 text-sm flex">
                            Visit Us <FaArrowRightLong className="ms-2 my-1" />
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Card;
