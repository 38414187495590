import { useQuery } from '@tanstack/react-query'
import { default as React, useContext, useEffect, useState } from 'react'
import { BsBookmark, BsFillBookmarkFill } from 'react-icons/bs'
import { MdOutlineArrowBack } from 'react-icons/md'
import { useNavigate, useParams } from 'react-router-dom'
import useRestAPI from '../api'
import { IMAGE_API_ROUTE } from '../api/constant'
import { poster } from '../api/poster'
import LoadingSVG from '../components/LoadingSvg'
import { AuthContext } from '../context/AuthContextProvider'

function ArticlePage() {
  const { articleId } = useParams()
  const { currentUser } = useContext(AuthContext)
  const {
    data: categoryData,
    refetch: categoryRefetch,
    isLoading: categoryLoading,
  } = useQuery(
    ['getArticle'],
    async () =>
      await poster({
        url: `/api/user/getArticle`,
        method: 'POST',
        bodyData: {
          articleId: articleId,
        },
      }),
    {
      retry: 3,
    },
  )

  //console.log(categoryData)
  const [isLoading, setLoading] = useState(false)
  const { getArticle, addBookmark, deleteBookmark } = useRestAPI()
  const navigate = useNavigate()
  const [article, setArticle] = useState(null)
  const isAuthenticated = localStorage.getItem('token')
  useEffect(() => {
    setLoading(true)
    return async () => {
      let data = await getArticle(articleId)
      if (data[0]['protection'] == '1' && !JSON.parse(isAuthenticated)){
        console.log(localStorage.getItem('token'))
        navigate('/auth/login'); // Navigate to the login page
        return;
      }


      setArticle(data[0])
      setLoading(false)
    }
  }, [])

  if (isLoading) {
    return <LoadingSVG />
  }
  return (
    <div>
      <div className="md:max-w-[1300px] md:mx-auto">
        <header className={`h-[100px] bg-cover bg-bottom`}>
          <span
            className={`flex gap-3 items-center font-bold cursor-pointer  p-5`}
            onClick={() => navigate(-1)}
          >
            <MdOutlineArrowBack />
            <span>Back</span>
          </span>
          <div>
            <h1 className="text-white hidden">Flowbite Blocks Tutorial</h1>
          </div>
        </header>
        <div className="relative bg-white rounded-t-md -mt-5 drop-shadow-xl px-8">
          <div className="flex justify-between text-left">
            <div className="flex h-[100px] justify-start">
              <div className="h-full">
                <img
                  className="rounded-tl-md rounded-bl-md p-4 object-contain md:w-full h-full"
                  src={`${IMAGE_API_ROUTE + article?.cover_image}`}
                  alt=""
                />
              </div>
              <div className="flex justify-between items-center w-full text-[#353535] font-medium ">
                <div className="text-left text-[#353535]">
                  <p className="text-5xl clamp-1">{article?.chapter_name}</p>
                </div>
              </div>
            </div>
            <div className="pt-8 flex justify-end ">
              {article?.is_bookmarked ? (
                <BsFillBookmarkFill
                  size={24}
                  cursor="pointer"
                  onClick={() => {
                    setLoading(true)
                    deleteBookmark(articleId)
                      .then(() => {
                        setArticle({
                          ...article,
                          is_bookmarked: false,
                        })
                        setLoading(false)
                      })
                      .catch(() => {
                        setLoading(false)
                      })
                  }}
                />
              ) : (
                <BsBookmark
                  size={24}
                  cursor="pointer"
                  onClick={() => {
                    setLoading(true)
                    addBookmark(articleId)
                      .then(() => {
                        setArticle({
                          ...article,
                          is_bookmarked: true,
                        })
                        setLoading(false)
                      })
                      .catch(() => {
                        setLoading(false)
                      })
                  }}
                />
              )}
              Bookmark
            </div>
          </div>
          <div
            className="py-8 article-post"
            dangerouslySetInnerHTML={{ __html: article?.content }}
          ></div>

          {article?.video_list
            ? JSON.parse(article?.video_list)?.map((video) => (
                <YoutubeVideo video={video} article={article} />
              ))
            : null}
        </div>
      </div>
    </div>
  )
}

const YoutubeVideo = ({ video, article, setArticle }) => {
  console.log(video);
  /*let category = [
    {
      name: 'Health',
      icon: process.env.PUBLIC_URL + '/images/categories/health.svg',
      vicon: '#B19DE5',
      bg: '#F1ECFF',
      id: '11',
    },
    {
      name: 'Equipment',
      icon: process.env.PUBLIC_URL + '/images/categories/equ.svg',
      vicon: '#F88B6F',
      bg: '#FFF3EC',
      id: '12',
    },
    {
      name: 'Environment',
      icon: process.env.PUBLIC_URL + '/images/categories/env.svg',
      vicon: '#4FD490',
      bg: '#ECFFF0',
      id: '13',
    },
    {
      name: 'Social needs',
      icon: process.env.PUBLIC_URL + '/images/categories/social.svg',
      vicon: '#F9C26F',
      bg: '#FFFDEC',
      id: '14',
    },
    {
      name: 'Miscellaneous',
      icon: process.env.PUBLIC_URL + '/images/categories/miscellaneous.svg',
      vicon: '#8B8279',
      bg: '#FFF4EC',
      id: '16',
    },
  ]*/
  //let title = category.find((item) => parseInt(item.id) === article.cid)
  return (
    <div>
      <div className="py-5  mx-6">
        {' '}
        {/* <div className="w-full"> */}
        <iframe
          width="100%"
          className="h-[17em] md:h-[500px] rounded-md"
          src={video.video}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        {/* </div> */}
      </div>
    </div>
  )
}
export default ArticlePage
