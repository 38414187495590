import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { IMAGE_API_ROUTE } from "../../api/constant";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTimes } from "react-icons/fa";
export default function TeamModal(props) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <button
        onClick={openModal}
        className=" text-sm px-6 py-2 rounded-full bg-primary-dark text-white flex items-center gap-3    "
      >
        Details
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1000]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[800px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 flex justify-between "
                  >
                    <div className="md:flex block gap-4">
                      <img
                        src={IMAGE_API_ROUTE + "/" + props.item.image}
                        alt=""
                        className="md:h-full md:max-w-[300px] w-[200px]"
                      />
                      <div className="">
                        <span> {props.item.name}</span>
                        <div className="font-normal">
                          <div className="mt-4">
                            {props.item.m_type}, {props.item.designation}
                          </div>

                          <div
                            className="mt-4"
                            dangerouslySetInnerHTML={{
                              __html: props.item.biography,
                            }}
                          ></div>
                          {props.item.fb ? (
                            <>
                              <div className="mt-4 flex gap-4 items-center">
                                <FaFacebookF /> {props.item.fb}
                              </div>
                            </>
                          ) : null}

                          {props.item.insta ? (
                            <>
                              <div className="mt-4 flex gap-4 items-center">
                                <FaInstagram /> {props.item.insta}
                              </div>
                            </>
                          ) : null}

                          {props.item.linkedin ? (
                            <>
                              <div className="mt-4 flex gap-4 items-center">
                                <FaLinkedinIn /> {props.item.linkedin}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <span className="cursor-pointer" onClick={closeModal}>
                      <FaTimes/>
                    </span>
                  </Dialog.Title>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
