// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PostCard.css */
/* Hide scrollbar for Webkit browsers */
  /* Hide scrollbar for Firefox */
  .scrollable {
    scrollbar-width: none; /* Firefox */
  }
  /* Hide scrollbar for Webkit browsers */
  .scrollable::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }`, "",{"version":3,"sources":["webpack://./src/pages/PostCard.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB,uCAAuC;EACrC,+BAA+B;EAC/B;IACE,qBAAqB,EAAE,YAAY;EACrC;EACA,uCAAuC;EACvC;IACE,aAAa,EAAE,sBAAsB;EACvC","sourcesContent":["/* PostCard.css */\n/* Hide scrollbar for Webkit browsers */\n  /* Hide scrollbar for Firefox */\n  .scrollable {\n    scrollbar-width: none; /* Firefox */\n  }\n  /* Hide scrollbar for Webkit browsers */\n  .scrollable::-webkit-scrollbar {\n    display: none; /* Safari and Chrome */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
