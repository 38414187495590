import React, { useContext, useEffect, useState } from 'react'
import {
  BsArrowLeftShort,
  BsBookmark,
  BsFillBookmarkFill,
} from 'react-icons/bs'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { Swiper, SwiperSlide } from 'swiper/react'
import useRestAPI from '../../api'
import { useQuery } from '@tanstack/react-query'
import { poster } from '../../api/poster'
import { AuthContext } from '../../context/AuthContextProvider'

function CareBookVideoCategory() {
  const { category: id } = useParams()
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { getVideoBookmarks } = useRestAPI()
  const [Videos, setVideos] = useState([])
  const { currentUser } = useContext(AuthContext)

  const {
    data: categoryData,
    refetch: categoryRefetch,
    isLoading: categoryLoading,
  } = useQuery(
    ['getVideoBookmarks'],
    async () =>
      await poster({
        url: `/api/user/getVideoBookmarks`,
        method: 'POST',
        bodyData: {
          categoryId: id,
          userId: currentUser?.id,
        },
      }),
  )
  let category = [
    {
      name: 'Health',
      icon: process.env.PUBLIC_URL + '/images/categories/health.svg',
      vicon: '#B19DE5',
      bg: '#F1ECFF',
      id: '11',
    },
    {
      name: 'Equipment',
      icon: process.env.PUBLIC_URL + '/images/categories/equ.svg',
      vicon: '#F88B6F',
      bg: '#FFF3EC',
      id: '12',
    },
    {
      name: 'Environment',
      icon: process.env.PUBLIC_URL + '/images/categories/env.svg',
      vicon: '#4FD490',
      bg: '#ECFFF0',
      id: '13',
    },
    {
      name: 'Social needs',
      icon: process.env.PUBLIC_URL + '/images/categories/social.svg',
      vicon: '#F9C26F',
      bg: '#FFFDEC',
      id: '14',
    },
    {
      name: 'Miscellaneous',
      icon: process.env.PUBLIC_URL + '/images/categories/miscellaneous.svg',
      vicon: '#8B8279',
      bg: '#FFF4EC',
      id: '16',
    },
  ]
  let title = category.find((item) => item.id === id)
  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    let data = await getVideoBookmarks(id)
    console.log(data)
    console.log(data)
    setVideos(data?.articles)
  }
  const YoutubeVideo = ({ category, setVideos, Videos, index }) => {
    const { deleteVideoBookmark, addVideoBookmark } = useRestAPI()
    return (
      <div>
        <div className="py-5  mx-6  ">
          {' '}
          {/* <div className="w-full"> */}
          <iframe
            width="100%"
            className="h-[17em] md:h-[500px] rounded-md"
            src={category.video}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          {/* </div> */}
          <div className=" bg-white  text-left py-2 px-2 rounded-bl-md rounded-br-md text-sm  text-[#04518D] text">
            <div className="flex items-center justify-between   gap-3">
              <div className="flex items-center  gap-3 px-5">
                {/* <img className="rounded-full w-8 " src={title?.icon} alt="i" /> */}
                <div>
                  <div className="text-[#353535]">{category.subcategory}</div>{' '}
                </div>
              </div>
              <div className="item-center flex justify-end ">
                {category?.is_bookmarked ? (
                  <BsFillBookmarkFill
                    size={24}
                    cursor="pointer"
                    onClick={() => {
                      setLoading(true)
                      deleteVideoBookmark(category.aid)
                        .then(() => {
                          categoryRefetch()
                          setLoading(false)
                        })
                        .catch(() => {
                          setLoading(false)
                        })
                    }}
                  />
                ) : (
                  <BsBookmark
                    size={24}
                    cursor="pointer"
                    onClick={() => {
                      setLoading(true)
                      addVideoBookmark(category.aid)
                        .then(() => {
                          categoryRefetch()
                          setLoading(false)
                        })
                        .catch(() => {
                          setLoading(false)
                        })
                    }}
                  />
                )}
                Bookmark
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className={`bg-[${title.bg}]`}>
        <div className="p-6">
          <div>
            <span
              className={`flex items-center font-bold cursor-pointer  text-[${title.vicon}]`}
              onClick={() => navigate(-1)}
            >
              <BsArrowLeftShort size={30} /> &nbsp; Back{' '}
            </span>
          </div>
          <h3 className="py-3 font-bold text-left">
            Care Videos-<span className="">{title.name}</span>
          </h3>

          <div className="md:max-w-[1300px] md:mx-auto">
            {categoryData?.articles?.map((category, key) => (
              <YoutubeVideo
                category={category}
                key={key}
                index={key}
                setVideos={setVideos}
                Videos={Videos}
              />
            ))}
          </div>
          {!categoryData?.articles?.length ? (
            <div>You don't have any bookmark </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default CareBookVideoCategory
