import React, { useContext, useEffect, useState } from 'react'
import questions from './questions.json'
import useRestAPI from '../../api'
import { useNavigate } from 'react-router-dom'
import { BsArrowLeftShort } from 'react-icons/bs'
import { AuthContext } from '../../context/AuthContextProvider'
import axios from 'axios'
import { API_ROUTE } from '../../api/constant'
const questionLength = questions.length
function Questions() {
  const [State, setState] = useState(0)
  const navigate = useNavigate()
  const [careReciver, setCareReciver] = useState(null)
  const { currentUser } = useContext(AuthContext)
  
  useEffect(() => {
    return async () => {
      await axios
        .post(API_ROUTE + '/api/user/getCareReceiver', {
          id: currentUser?.id,
        })
        .then((res) => {
          //console.log(res.data)
          if (res.data.status) {
            setCareReciver(res.data[0])
          } else {
            //console.log(res.data.error)
            setCareReciver(null)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, []);
  if(!currentUser){
    return (<>User not found</>);
  }
  return (
    <div>
      <div className="px-6 py-5">
        <div className=" rounded-md border-2 bg-white drop-shadow-xl max-w-md py-5  mx-auto ">
          <div>
            <span
              className={`flex items-center font-bold cursor-pointer `}
              onClick={() => navigate(-1)}
            >
              <BsArrowLeftShort size={30} /> &nbsp; Back{' '}
            </span>
          </div>
          <h3 className="text-center text-xl font-bold">
            <span>Question {State + 1}/20</span>{' '}
          </h3>
          <div className="px-5 py-5">
            <Question
              question={questions[State]}
              setState={setState}
              State={State}
              name={careReciver?.cr_nicname}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function Question({ question, setState, State, name }) {
  const { carePlanQuestions, getCarePlanQuestions } = useRestAPI()

  const navigate = useNavigate()

  const [Answer, setAnswer] = useState({
    checkboxAnswer: {},
    isRadio: false,
    comment: '',
  })
  useEffect(() => {
    const getData = async () => {
      let data = await getCarePlanQuestions()

      // if (!JSON.parse(data['ques' + (State + 1)])) {
      //   setAnswer({
      //     checkboxAnswer: {},
      //     isRadio: false,
      //     comment: '',
      //   })
      // } else {
      //   setAnswer({ ...Answer, ...JSON.parse(data['ques' + (State + 1)]) })
      // }



      if (data && data['ques' + (State + 1)]) {
        setAnswer({ ...Answer, ...JSON.parse(data['ques' + (State + 1)]) });
      } else {
        setAnswer({
          checkboxAnswer: {},
          isRadio: false,
          comment: '',
        });
      }
  
    }
    getData()
  }, [State])

  const handleRadio = () => {
    console.log(Answer)
    if (!Answer.isRadio) {
      setAnswer({
        ...Answer,
        checkboxAnswer: {},
        isRadio: true,
      })
    } else {
      setAnswer({
        ...Answer,
        checkboxAnswer: {},
        isRadio: false,
      })
    }
  }

  const isAnswerChecked = () => {
    if (!Answer.isRadio && (Answer.checkboxAnswer.length < 0 || isNullish)) {
      alert('Please select at least one  answer')
      return false
    }
    return true
  }
  const isNullish = Object.values(Answer.checkboxAnswer).every((value) => {
    if (value === false) {
      return true
    }

    return false
  })
  return (
    <>
      <div>
        <div className="pb-8">
          <h3 className="font-bold">
            {question?.question?.replace('{name}', name)}
          </h3>
          <div className="py-3">
            {question.radioQuestion && (
              <div className="flex items-center">
                <input
                  type="radio"
                  onChange={handleRadio}
                  value={Answer.isRadio}
                  checked={Answer.isRadio}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ml-2 text-sm font-medium text-[#414042] ">
                  {question.radioQuestion}
                </label>
              </div>
            )}

            <p className="text-center py-3 text-[#A1A1A1]">
              { [1,2,5,9,11,12,17,20].includes(question.id) ? " tick one only" : " tick all that apply"}
              
            </p>
            <div>
              {question?.checkboxQuestion?.map((val, index) => (
                
                  <div key={index} className="">
                    <div>
                      <div className="flex items-center mb-4">
                        <input
                          type="checkbox"
                          value={
                            Answer.checkboxAnswer[index] ??
                            Answer.checkboxAnswer[index]
                          }
                          checked={
                            Answer.checkboxAnswer[index] ??
                            Answer.checkboxAnswer[index]
                          }
                          onChange={() => {
                            setAnswer({
                              ...Answer,
                              checkboxAnswer: {
                                ...Answer.checkboxAnswer,
                                [index]: !Answer.checkboxAnswer[index],
                              },
                              isRadio: false,
                            })
                          }}
                          name={index}
                          defaultValue
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="ml-2 text-sm font-medium ">
                          {val}
                        </label>
                      </div>
                    </div>
                  </div>
               
              ))}
              <div>
                <h4 className="text-black font-medium py-3 ">Comments</h4>
                <textarea
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-gray-400 w-full"
                  placeholder="Write your comments here "
                  value={Answer.comment}
                  onChange={(e) => {
                    setAnswer({
                      ...Answer,
                      comment: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center gap-10">
          {State > 0 && (
            <button
              className="bg-[#0566B1] px-5 py-2 text-white  rounded-sm"
              onClick={() => {
                if (State > 1) {
                  setState(State - 1)
                } else {
                  setState(0)
                }
              }}
            >
              Back
            </button>
          )}
          {State === 19 && (
            <button
              className="bg-[#0566B1] px-5 py-2 text-white  rounded-sm"
              onClick={() => {
                if (isAnswerChecked()) {
                  carePlanQuestions({
                    ['ques' + (State + 1)]: JSON.stringify(Answer),
                  })
                  navigate(-1)
                }
              }}
            >
              Save
            </button>
          )}
          {State + 1 < questionLength && (
            <button
              className="bg-[#0566B1] px-5 py-2 text-white  rounded-sm"
              onClick={() => {
                if (State + 1 === 19) {
                  if (Answer.comment === '') {
                    alert('Please Add  comment ')
                  } else {
                    carePlanQuestions({
                      ['ques' + (State + 1)]: JSON.stringify(Answer),
                    })
                    setState(State + 1)
                  }
                } else if (isAnswerChecked()) {
                  carePlanQuestions({
                    ['ques' + (State + 1)]: JSON.stringify(Answer),
                  })
                  setState(State + 1)
                }
              }}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </>
  )
}
export default Questions
