import React from 'react';
import Community from './Community';

import PostList from './PostList';

const MyPost = () => {
  let userId = JSON.parse(localStorage.getItem("token")).id;

  return (
    <div>
        <Community />
        <PostList userId={userId} />
    </div>
  )
}

export default MyPost
