import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillGoogleCircle,
} from 'react-icons/ai'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { AuthContext } from '../../context/AuthContextProvider'
function Register() {
  const { handleRegister, handleGoogleSignIn } = useContext(AuthContext)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [isPasswordVisible2, setIsPasswordVisible2] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      password: '',
      confirmPassword: '',
      agreedTerms: false
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),

      name: Yup.string().required('Name  is required'),
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters long'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
      agreedTerms: Yup.boolean()
        .oneOf([true], 'You must agree to the Terms & Conditions')
        .required('You must agree to the Terms & Conditions')
    }),
    onSubmit: (values) => {
      handleRegister(values)
    },
  })
  return (
    <div>
      <div>
        <div className="px-6 md:max-w-[1300px] md:mx-auto">
          <h3 className="text-start py-5 ">Create an account</h3>
          <div className="w-full ">
            <form
              className="flex flex-col gap-4"
              onSubmit={formik.handleSubmit}
            >
              {/* //Email */}
              <div className="w-full">
                <label>Email</label>
                <input
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                  placeholder="email"
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
              {/* //Phone  */}
              <div className="w-full">
                <label>Name </label>
                <input
                  className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                  placeholder="name"
                  type="text"
                  name="name"
                  // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
              {/* //Password  */}
              <div className="w-full">
                <label>Password</label>
                <div className="flex">
                  <input
                    className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                    placeholder="password"
                    type={isPasswordVisible ? 'text' : 'password'}
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    class="cursor-pointer  inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-r-md "
                  >
                    {isPasswordVisible ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </span>
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>
              {/* //Conirm Password  */}
              <div className="w-full">
                <label>Confirm Password</label>
                <div className="flex">
                  <input
                    className="placeholder:text-primary-green border px-3 py-2 rounded-md border-primary-green w-full"
                    placeholder="Confirm Password"
                    type={isPasswordVisible2 ? 'text' : 'password'}
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <span
                    onClick={() => setIsPasswordVisible2(!isPasswordVisible2)}
                    class="cursor-pointer  inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-r-md "
                  >
                    {isPasswordVisible ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </span>
                </div>
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                  <div className="text-start text-red-600 text-sm ">
                    {formik.errors.confirmPassword}
                  </div>
                ) : null}
              </div>
              {/* Terms and condition checkbox */}
              <label class="checkbox-container ">
                <input
                  type="checkbox"
                  name="agreedTerms"
                  checked={formik.values.agreedTerms}
                  onChange={formik.handleChange}
                />
                <span class="checkmark"></span>
                <span class="label-text"> I understand and agree with the <Link style={{ color: 'rgb(5, 102, 177)' }} to="/privacy">Terms & Conditions</Link></span>
              </label>
              {formik.touched.agreedTerms && formik.errors.agreedTerms ? (
                <div className="text-start text-red-600 text-sm">
                  {formik.errors.agreedTerms}
                </div>
              ) : null}
              <button className="bg-primary-dark  text-white py-2 rounded-md min-w-[150px]">
                Create Account
              </button>
            </form>

            <div>
              <div class="px-6 sm:px-0 max-w-sm mx-auto mt-5">
                <button
                  onClick={() => handleGoogleSignIn(false)}
                  type="button"
                  class="text-white w-full  bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-between dark:focus:ring-[#4285F4]/55 mr-2 mb-2"
                >
                  <AiFillGoogleCircle size={24} />
                  Register with Google<div></div>
                </button>
              </div>
            </div>
            <div className="text-center my-5 ">
              <h4>
                <span>
                  Already have an account?<br></br>
                </span>
                <Link to="/auth/login">
                  <span className="text-[#0566B1] font-semibold">
                    Login here
                  </span>
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
